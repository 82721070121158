import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Container,
  InputAdornment,
  IconButton,
  Typography,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import DateTimePicker from '../DateTimePicker';
import HTMLToolTip from '../HTMLToolTip';
import constants from '../../utils/constants';
const keyMap = {
  digiCert: {
    label: 'DigiCert',
    key: 'id',
    name: 'common_name',
  },
  appRegistration: {
    label: 'Azure - App Registration',
    key: 'appId',
    name: 'displayName',
  },
  enterpriseApplication: {
    label: 'Azure - Enterprise Apps',
    key: 'appId',
    name: 'displayName',
  },
  sectigocert: {
    label: 'Azure - Enterprise Apps',
    key: 'appId',
    name: 'displayName',
  },
  others: {
    label: 'Others',
    key: 'key',
    name: 'name',
  },
};

function SummaryTab({
  
  source, certData, newCert, handleChange,
}) {

  const handleTextFieldChange = (fieldId, value) => {
    handleChange(fieldId, value);
  };

  return (
    <Container>
      <Grid
        container
        spacing={0.5}
      >
        {!newCert && (
        <Grid item xs={12}>
          <TextField
            id="key"
            value={certData.key || ''}
            onChange={(e) => handleTextFieldChange('key', e.target.value)}
            margin="normal"
            type={source === constants.parentType.digiCert ? 'number' : 'text'}
            label={source === constants.parentType.appRegistration ? 'Application ID' : 'ID'}
            variant="outlined"
            size="small"
            fullWidth
            disabled={!newCert || source === constants.parentType.route53 || source === constants.parentType.sectigoCert}
            InputProps={{
              endAdornment: (
                <>
                  {source != constants.parentType.sectigoCert && certData.data && certData.key !== certData.data[keyMap[source]['key']]
                && (
                <InputAdornment position="end">
                  <HTMLToolTip
                    placement="top-end"
                    title={
                      <>
                        <Typography color="inherit">Source data conflict!</Typography>
                        Field&apos;s value is different at source
                        {' '}
                        <em><b>{keyMap[source]['label']}</b></em>
                        {' '}
                        Source value -
                        {' '}
                        <b>{certData.data[keyMap[source]['key']]}</b>
                      </>
                    }
                  >
                    <IconButton
                      aria-label="source data update"
                      edge="end"
                    >
                      <ErrorOutline color="warning" />
                    </IconButton>
                  </HTMLToolTip>
                </InputAdornment>)}
                </>
              ),
            }}
          />
        </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            id="name"
            value={certData.name || ''}
            onChange={(e) => handleTextFieldChange('name', e.target.value)}
            margin="normal"
            label="Display Name"
            size="small"
            fullWidth
            disabled={source === constants.parentType.route53 || source === constants.parentType.sectigoCert}
            InputProps={{
              endAdornment: (
                <>
                  {source != constants.parentType.sectigoCert && certData.data && certData.name !== certData.data[keyMap[source]['name']]
                && (
                <InputAdornment position="end">
                  <HTMLToolTip
                    placement="top-end"
                    title={
                      <>
                        <Typography color="inherit">Source data conflict!</Typography>
                        Field&apos;s value is different at source
                        {' '}
                        <em><b>{keyMap[source]['label']}</b></em>
                        <br />
                        Source value -
                        {' '}
                        <b>{certData.data[keyMap[source]['name']]}</b>
                      </>
                    }
                  >
                    <IconButton
                      aria-label="source data update"
                      edge="end"
                    >
                      <ErrorOutline color="warning" />
                    </IconButton>
                  </HTMLToolTip>
                </InputAdornment>)}
                </>
              ),
            }}
          />
        </Grid>
        {(source === constants.parentType.appRegistration
        || source === constants.parentType.enterpriseApplication)
        && (
        <>
          <Grid item md={6} xs={12}>
            <TextField
              id="interactive_logins"
              value={certData?.data?.interactive_logins?.count || 0}
              onChange={(e) => handleTextFieldChange('interactive_logins', e.target.value)}
              margin="normal"
              label="Interactive sign in"
              size="small"
              fullWidth
              disabled
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              id="non_interactive_logins"
              value={certData?.data?.non_interactive_logins?.count || 0}
              onChange={(e) => handleTextFieldChange('non_interactive_logins', e.target.value)}
              margin="normal"
              label="Non interactive sign in"
              size="small"
              fullWidth

              disabled
            />
          </Grid>
        </>)}

        <Grid item xs={6}>
          <DateTimePicker
            id="validity_start_date"
            value={certData.validity_start_date || ''}
            onChange={(e) => handleTextFieldChange('validity_start_date', e ? parseInt(e, 10) : '')}
            margin="normal"
            label="Validity start date"
            size="small"
            fullWidth
            disabled={source === constants.parentType.route53 || source === constants.parentType.sectigoCert}
          />
        </Grid>

        <Grid item xs={6}>
          <DateTimePicker
            id="validity_expiry_date"
            value={certData.validity_expiry_date || ''}
            onChange={(e) => handleTextFieldChange('validity_expiry_date', e ? parseInt(e, 10) : '')}
            margin="normal"
            label="Validity end date"
            size="small"
            fullWidth
            disabled={source === constants.parentType.route53 || source === constants.parentType.sectigoCert}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="notes"
            value={certData.notes || ''}
            onChange={(e) => handleTextFieldChange('notes', e.target.value)}
            margin="normal"
            label="Notes"
            size="small"
            multiline
            rows={4}
            fullWidth
          />
        </Grid>
      </Grid>
    </Container>

  );
}

SummaryTab.propTypes = {
  source: PropTypes.string.isRequired,
  certData: PropTypes.objectOf(PropTypes.object).isRequired,
  newCert: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default SummaryTab;
