import React, { useEffect, useState ,useMemo } from 'react';
import { Grid, Button, Snackbar, Alert, AlertTitle, TableContainer,Table, TableHead, TableBody, TableRow, TableCell, Paper, Hidden } from '@mui/material';
import PageTitle from '../../components/PageTitle';
import axios from '../../utils/axios';
import constants from '../../utils/constants';
import moment from 'moment';
import CertificatesSummary from './certificateSummaryCard';
import DataTable from '../../components/DataTable';
import LinkCellRenderer from '../../components/DataTable/LinkCellRenderer';

function Dashboard() {
  const gridSpacing = 3;
  const [isLoading, setLoading] = useState(true);
  const [expirableItems, setExpirableItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageRows, setPageRows] = useState([]);
  const containerStyle = useMemo(() => ({ height: '40%' , width:'700px'  }), []);
  const containerStyleforpage = useMemo(() => ({ height: '350px'  }), []);

  const handleClick = () => {
    setOpen(true);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const columnDefs = [
    {
      headerName: 'Name',
      field: 'name',
    cellRenderer: cellRendererFunction,
    },
    {headerName: 'Id',
      field: 'id', 
      hide: 'true',
    },
    {
      headerName: 'Type',
      field: 'type',
      
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry Date'
    },
    {
      headerName: 'Expiry On',
      field: 'expiryOn',
      hide: 'true',
      sort: 'asc',
    }
    ,
    {
      headerName: 'Jira Issues',
      field: 'jira_issues',
      cellRenderer: (params) => {
        return params.value && params.value.length > 0 ? params.value.map((issue) => {
          return <a href={`https://jira.abc.com/browse/${issue}`} target="_blank" rel="noreferrer">{issue}</a>;
        }).reduce((prev, curr) => [prev, ', ', curr]) : '';
      }
    }
  ];
  
  function cellRendererFunction(params) {
    let navigateUrl = '/certificates';
    if (params?.data?.type === constants.parentType.appRegistration) {
      navigateUrl += '/appregistration';
    } else if (params?.data?.type === constants.parentType.enterpriseApplication) {
      navigateUrl += '/enterprise';
    } else if (params?.data?.type === constants.parentType.digiCert) {
      navigateUrl += '/digicert';
    } else if (params?.data?.type === constants.parentType.route53) {
      navigateUrl += '/route53';
    } else if (params?.data?.type === constants.parentType.sectigoCert) {
      navigateUrl += '/sectigo';  
    } else if (params?.data?.type === constants.parentType.others) {
      navigateUrl += '/misc';
    }
    
    navigateUrl += `/cert/${params?.data?.id}`;
    return (<LinkCellRenderer {...params} navigateUrl={navigateUrl} />);
  }


  const columnpageDefs = [
    {
      headerName: 'Name',
      field: 'name',
    cellRenderer: cellRendererFunction,
    },
    {headerName: 'Id',
      field: 'id', 
      hide: 'true',
    },
    {
      headerName: 'Type',
      field: 'type',
    },

    {
      field: 'expiryDate',
      headerName: 'Expiry Date'
    },
    {
      headerName: 'Expiry On',
      field: 'expiryOn',
      hide: 'true',
      sort: 'asc',
    },
    {
      headerName: 'Jira Issues',
      field: 'jira_issues'

    }
    
  ];


  const fetchData = () => {
    axios.get('/api/certscanner/expirableitems')
      .then((response) => {
        setLoading(false);
        createNoificationData(response.data)
        setExpirableItems(response.data);
      
      });
  };

  


  useEffect(() => {
    if (expirableItems.length > 0) {
      sessionStorage.expirableitems = JSON.stringify(expirableItems);
    }
  }, [expirableItems]);

  const createNoificationData = (expirableItem) => {
    const dataNexttrday = [];
    const dataNextsxday = [];
    expirableItem.forEach((item) => {
      if (item.metadata.expiryOn ){
      const expiryDate = moment(item.metadata.expiryOn * 1000);
      const diff = expiryDate.diff(moment(), 'days');
      if (diff >= 0 && diff <= 30) {
        dataNexttrday.push({
          id: item.keyId ? item.keyId : item.key,
          name: item.displayName && item.name != "" ? item.displayName :
            item.metadata && item.metadata.displayName ? item.metadata.displayName : "",
          expiryDate: expiryDate.format('DD-MM-YYYY'),
          type: item.metadata.parentType,
          expiryOn: item.metadata.expiryOn * 1000,
          jira_issues: item.jira_issues && item.jira_issues.length > 0 ? item.jira_issues.map(function (el) { return el.key; }) : "" 
        });
      }
      if (diff >= -1111110 && diff <= 10000) {
        dataNextsxday.push({
          id: item.keyId ? item.keyId : item.key,
          name: item.displayName && item.name != "" ? item.displayName :
            item.metadata && item.metadata.displayName ? item.metadata.displayName : "",
          expiryDate: expiryDate.format('DD-MM-YYYY'),
          type: item.metadata.parentType,
          expiryOn: item.metadata.expiryOn * 100,
          jira_issues: item.jira_issues && item.jira_issues.length > 0 ? item.jira_issues.map(function (el) { return el.key; }) : ""
        });
      }
    }
    });
    setRows(dataNexttrday);
    setPageRows(dataNextsxday);
    handleClick();
  }

  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.expirableitems || '[]');
    if (storedData.length > 0) {
      setLoading(false);
      createNoificationData(storedData)
      setExpirableItems(storedData);
    } else {
      fetchData();
    }
  }, []);

  const handleRefresh = () => {
    setLoading(true);
    fetchData();
  };

  return (
    <>
      
      <PageTitle title="Dashboard" actionButton="Refresh" buttonOnClick={handleRefresh} />
        <Snackbar open={open} autoHideDuration={12000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
        style= {{ height : "98%" }}  >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%', height: '100%', backgroundColor: 'red' }}
          >
          <AlertTitle>Certificate Expiry in Next 30 Days</AlertTitle>
          <div style={containerStyle}>
                <DataTable
                  rowData={rows}
                  columnDefs={columnDefs}
                />
          </div>

          </Alert>
        </Snackbar>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CertificatesSummary
                type={constants.parentType.appRegistration}
                isLoading={isLoading}
                expirableItems={expirableItems}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CertificatesSummary
                type={constants.parentType.enterpriseApplication}
                isLoading={isLoading}
                expirableItems={expirableItems}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CertificatesSummary
                type={constants.parentType.digiCert}
                isLoading={isLoading}
                expirableItems={expirableItems}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CertificatesSummary
                type={constants.parentType.route53}
                isLoading={isLoading}
                expirableItems={expirableItems}
              />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CertificatesSummary
                type={constants.parentType.sectigoCert}
                isLoading={isLoading}
                expirableItems={expirableItems}
              />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CertificatesSummary
                type={constants.parentType.others}
                isLoading={isLoading}
                expirableItems={expirableItems}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PageTitle title="Certificates Expiring in Next 60 Days" />
      <div style={containerStyleforpage}>
                <DataTable
                  rowData={pageRows}
                  columnDefs={columnpageDefs}
                  // onGridReady={onGridReady}
                />
      </div>
    </>
  );
}

export default Dashboard;
