import { adaptV4Theme } from '@mui/material/styles';

import { createTheme } from '@mui/material';
import defaultTheme from './default';

const overrides = {
  typography: {
    fontSize: 13,
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.285rem',
    },
    h6: {
      fontSize: '1.142rem',
    },
    commonAvatar: {
      cursor: 'pointer',
      borderRadius: '8px',
    },
    smallAvatar: {
      width: '22px',
      height: '22px',
      fontSize: '1rem',
    },
    mediumAvatar: {
      width: '34px',
      height: '34px',
      fontSize: '1.2rem',
    },
    largeAvatar: {
      width: '44px',
      height: '44px',
      fontSize: '1.5rem',
    },
  },
  // palette: {
  //   mode: 'dark',
  // },
};

export default {
  default: createTheme(adaptV4Theme({ ...defaultTheme, ...overrides })),
};
