import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import moment from 'moment';

function DirectoryAuditLogs({ auditHistory }) {
  console.log('props', auditHistory);
  return (
    <>
      {auditHistory.map((historyObj) => (
        <Paper
          sx={{
            p: 2,
            marginBottom: 2,
            flexGrow: 1,
            backgroundColor: '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    <b>Activity:</b>
                    {' '}
                    {historyObj.activityDisplayName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" component="div">
                  <b>Date:</b>
                  {' '}
                  {moment(historyObj.activityDateTime).format('MM/DD/yyyy hh:mm A')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Typography variant="body2" gutterBottom>
                <b>Initiated by:</b>
                {' '}
                {historyObj.initiatedBy?.user && historyObj.initiatedBy.user.userPrincipalName}
                {' '}
                {historyObj.initiatedBy?.app && historyObj.initiatedBy.app.displayName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Typography variant="body2" gutterBottom>
                <b>Modified Properties:</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              {historyObj?.targetResources.map((targetResource) => (
                <Paper
                  sx={{
                    p: 2,
                    mt: 1,
                    mb: 1,
                    flexGrow: 1,
                    backgroundColor: '#fff',
                  }}
                >
                  {targetResource?.modifiedProperties.map((modifiedData) => (
                    <>
                      <Grid item sx={{ marginBottom: 1 }}>
                        <Typography variant="body2">
                          <b>{modifiedData.displayName}</b>
                        </Typography>
                      </Grid>
                      <Grid container sx={{ marginBottom: 2 }}>
                        {modifiedData.oldValue && (
                        <Grid item sm={6}>
                          <Typography variant="body2" sx={{ p: 1 }}>
                            <b>
                              Old value
                            </b>
                          </Typography>
                          <Box
                            component="span"
                            sx={{
                              display: 'block',
                              p: 1,
                              m: 1,
                              backgroundColor: '#f2f1f1',
                              border: '1px solid',
                              borderColor: 'grey.300',
                              borderRadius: 2,
                              fontSize: '12px',
                              fontWeight: '500',
                              wordWrap: 'break-word',
                            }}
                          >
                            {modifiedData.oldValue}
                          </Box>
                        </Grid>
                        )}
                        <Grid item sm={6}>
                          <Typography variant="body2" sx={{ p: 1 }}>
                            <b>
                              New value
                            </b>
                          </Typography>
                          <Box
                            component="span"
                            sx={{
                              display: 'block',
                              p: 1,
                              m: 1,
                              backgroundColor: '#f2f1f1',
                              border: '1px solid',
                              borderColor: 'grey.300',
                              borderRadius: 2,
                              fontSize: '12px',
                              fontWeight: '500',
                              wordWrap: 'break-word',
                            }}
                          >
                            {modifiedData.newValue}
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider item sx={{ marginBottom: 2 }} />
                    </>
                  ))}
                </Paper>
              ))}
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  );
}

DirectoryAuditLogs.propTypes = {
  auditHistory: PropTypes.instanceOf(Array),
};

export default DirectoryAuditLogs;
