/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Link } from '@mui/material';
import BreadCrumbs from '../BreadCrumbs';

function CertificateFormHeader({ breadCrumbs, azureRedirectUrl }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={9} md={9}>
        {breadCrumbs?.length > 0 && <BreadCrumbs links={breadCrumbs} />}
      </Grid>
      <Grid item xs={12} sm={3} md={3} sx={{ mt: 1, mb: 1, textAlign: 'right' }}>
        {azureRedirectUrl?.length > 0 && (
          <Link href={azureRedirectUrl} underline="hover" target="_blank">
            View in Azure Portal
          </Link>
        )}
      </Grid>
    </Grid>
  );
}

CertificateFormHeader.propTypes = {
  breadCrumbs: PropTypes.instanceOf(Array),
  azureRedirectUrl: PropTypes.string,
};

export default CertificateFormHeader;
