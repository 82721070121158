import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function BasicDatePicker({
  value, label, onChange, ...props
}) {
  const [dateValue, setValue] = useState(value ? moment(value, 'X') : null);

  useEffect(() => {
    setValue(value ? moment(value, 'X') : null);
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label || 'Select Date'}
        value={dateValue}
        onChange={(newValue) => {
          setValue(newValue);
          onChange(newValue.$d ? moment(newValue.$d).format('X') : '');
        }}
        renderInput={
          (params) => <TextField {...params} {...props} />
        }
      />
    </LocalizationProvider>
  );
}

BasicDatePicker.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default BasicDatePicker;
