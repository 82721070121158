/* eslint-disable */
import React from 'react';
import { createBrowserHistory } from 'history';
import {
  Route, Switch, Redirect, BrowserRouter,
} from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

// components
import Layout from './components/Layout';

// pages
import Login from './pages/login';
import Logout from './pages/logOut';
import AccessToken from './pages/accessToken';

export default function Routes() {
  const hist = createBrowserHistory();

  return (
    <BrowserRouter history={hist}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route exact path="/app" render={() => <Redirect to="/dashboard" />} />
        <Route path="/accesstoken" component={AccessToken} />
        <Route path="/logout" component={Logout} />

        <PrivateRoute path="/app" component={Layout} />
        <PrivateRoute path="/dashboard" component={Layout} />
        <PrivateRoute path="/expiring" component={Layout} />
        <PrivateRoute path="/certificates" component={Layout} />
        <PrivateRoute path="/keys" component={Layout} />

        <RedirectRoute path="/login" component={Login} />
        {/* <Route component={Login} /> */}
      </Switch>
    </BrowserRouter>
  );

  // #######################################################################
  function PrivateRoute({ component, ...rest }) {
    const isAuthenticated = useIsAuthenticated();
    return (
      <Route
        {...rest}
        render={(props) => (isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        ))}
      />
    );
  }

  function RedirectRoute({ component, ...rest }) {
    const isAuthenticated = useIsAuthenticated();
    return (
      <Route
        {...rest}
        render={(props) => (isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/dashboard',
            }}
          />
        ) : (
          React.createElement(component, props)
        ))}
      />
    );
  }
}
