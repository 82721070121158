import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Prompt = (title, message, onConfirm) => {
  confirmAlert({
    title,
    message,
    buttons: [
      {
        label: 'Yes',
        onClick: onConfirm,
      },
      {
        label: 'Cancel',
      },
    ],
  });
};

export default Prompt;
