import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer, IconButton, List, useTheme, Typography, Link,
} from '@mui/material';
import {
  Home as HomeIcon,
  BorderAll as TableIcon,
  ArrowBack as ArrowBackIcon,
  DateRange as DateRangeIcon,
} from '@mui/icons-material';

import { withRouter, Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import SidebarLink from './components/SidebarLink/SidebarLink';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';

const structure = [
  {
    id: 0, label: 'Dashboard', link: '/dashboard', icon: <HomeIcon />,
  },
  {
    id: 1, label: 'Expiring Items', link: '/expiring', icon: <DateRangeIcon />,
  },
  { id: 2, type: 'divider' },
  { id: 3, type: 'title', label: 'Certificates' },
  {
    id: 4, label: 'App Registrations', link: '/certificates/appregistration', icon: <TableIcon />,
  },
  {
    id: 5, label: 'Enterprise', link: '/certificates/enterprise', icon: <TableIcon />,
  },
  // {
  //   id: 6, label: 'Combined', link: '/certificates/combinedapps', icon: <TableIcon />,
  // },
  // { id: 7, type: 'title', label: 'Other Certificates' },
  {
    id: 8, label: 'DigiCert', link: '/certificates/digicert', icon: <TableIcon />,
  },
  {
    id: 15, label: 'Route 53', link: '/certificates/route53', icon: <TableIcon />,
  },
  {
    id: 16, label: 'SectigoCert', link: '/certificates/sectigo', icon: <TableIcon />,
  },
  { id: 9, type: 'divider' },
  { id: 10, type: 'title', label: 'Access keys & Tokens' },
  {
    id: 11, label: 'Github Access Token', link: '/keys/github', icon: <TableIcon />,
  },
  { id: 12, type: 'divider' },
  { id: 13, type: 'title', label: 'Others' },
  {
    id: 14, label: 'Miscellaneous', link: '/certificates/misc', icon: <TableIcon />,
  },
];

function Sidebar({ location }) {
  const classes = useStyles();
  const theme = useTheme();
  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);

  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar}>
        <Typography variant="body2" weight="medium" className={classes.logotype}>
          <Link component={RouterLink} to="/dashboard">
            <img alt="logo" className={classes.logotypeImage} />
          </Link>
        </Typography>
      </div>
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)} size="large">
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );
}

Sidebar.propTypes = {
  location: PropTypes.object,
};

export default withRouter(Sidebar);
