import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Container,
} from '@mui/material';

function BasicSAMLConfigurationTab({
  certData, handleChange, servicePrinicple,
}) {
  const handleTextFieldChange = (fieldId, value) => {
    handleChange(fieldId, value);
  };

  console.log('certData', certData);

  return (
    <Container>
      <Grid
        container
        spacing={0.5}
      >
        <Grid item xs={12}>
          <TextField
            id="identifier"
            value={servicePrinicple?.servicePrincipalNames?.length > 0 ? servicePrinicple.servicePrincipalNames[0] : ''}
            onChange={(e) => handleTextFieldChange('identifier', e.target.value)}
            margin="normal"
            label="Identifier (Entity ID)"
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="reply_url"
            value={servicePrinicple?.replyUrls?.length > 0 ? servicePrinicple.replyUrls[0] : ''}
            onChange={(e) => handleTextFieldChange('reply_url', e.target.value)}
            margin="normal"
            label="Reply URL"
            size="small"
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="sign_on_url"
            value={servicePrinicple?.loginUrl || ''}
            onChange={(e) => handleTextFieldChange('sign_on_url', e.target.value)}
            margin="normal"
            label="Sign on URL"
            size="small"
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="relay_state"
            value={servicePrinicple?.samlSingleSignOnSettings?.relayState || ''}
            onChange={(e) => handleTextFieldChange('relay_state', e.target.value)}
            margin="normal"
            label="Relay State"
            size="small"
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="logout_url"
            value={servicePrinicple?.logoutUrl || ''}
            onChange={(e) => handleTextFieldChange('logout_url', e.target.value)}
            margin="normal"
            label="Logout URL"
            size="small"
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>
    </Container>

  );
}

BasicSAMLConfigurationTab.propTypes = {
  certData: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  servicePrinicple: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default BasicSAMLConfigurationTab;
