import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Link,
} from '@mui/material';
import { withRouter } from 'react-router-dom';

// styles
import useStyles from './styles';

// context
import { useUserDispatch, useUserState, loginUser } from '../../context/UserContext';

function Login() {
  const { instance } = useMsal();
  const classes = useStyles();

  // global
  const userDispatch = useUserDispatch();
  const { error, loading } = useUserState();

  const [activeTabId, setActiveTabId] = useState(0);
  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <div className={classes.logoOverLay}>
          <div className={classes.bannerContent}>
            <img src="/images/logo.svg" alt="logo" className={classes.logotypeImage} />
            <Typography variant="h4" className={classes.logotypeText}>SSL Certificates</Typography>
          </div>
        </div>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <>

              {false && (
              <>
                {' '}
                <TextField
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={loginValue}
                  onChange={(e) => setLoginValue(e.target.value)}
                  margin="normal"
                  placeholder="Email Address"
                  type="email"
                  fullWidth
                />
                <TextField
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={passwordValue}
                  onChange={(e) => setPasswordValue(e.target.value)}
                  margin="normal"
                  placeholder="Password"
                  type="password"
                  fullWidth
                />
              </>
              )}

              {error && (
              <Fade in={!!error}>
                <Typography color="error" className={classes.errorMessage}>
                  {error}
                </Typography>
              </Fade>
              )}

              <div className={classes.formButtons}>
                {loading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    onClick={() => loginUser(
                      userDispatch,
                      instance,
                    )}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    LOG IN AS PROLOGIS EMPLOYEE
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
        <Typography variant="body2" className={classes.copyright}>
          Copyright ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          Prologis, Inc. All rights reserved.
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ color: '#A7A9AB', paddingTop: '10px' }}
          >
            <Grid item xs={5}>
              <Link
                variant="body2"
                color="inherit"
                href="https://www.prologis.com/terms-of-use"
                target="_blank"
                rel="noopener"
              >
                Terms of use
              </Link>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5} style={{ textAlign: 'right' }}>
              <Link
                variant="body2"
                color="inherit"
                href="https://www.prologis.com/privacy-policy"
                target="_blank"
                rel="noopener"
              >
                Privacy Policy
              </Link>
            </Grid>
          </Grid>
        </Typography>
      </div>
    </Grid>
  );
}

Login.propTypes = {
};

export default withRouter(Login);
