import config from '../config.json';
import { loginRequest, graphConfig } from '../authConfig';
import { msalInstance } from '../index';

/// //////////////////////////////
// Common Values functionality //
/// //////////////////////////////
export function getEndpoint() {
  const endpoint = window.location.host;
  if (window.location.hostname.includes('localhost') || window.location.hostname.startsWith('192.168.')) {
    // console.log("Running in development mode, targeting dev endpoint")
    return config.endpoint;
  }
  // if the source is not in my list of endpoints return none
  if (endpoint.endsWith('.sslcertscanner.prologis.com') || endpoint.endsWith('.sslcertscanner.prologisweb.com')) {
    return `https://${endpoint}`;
  }
  // TODO: This needs to get the endpoint from the anchor somehow
  return 'https://sslcertscanner.prologisweb.com';
}

export async function callMsGraph(token) {
  let accessToken = token;
  let userRoles = ['user'];
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    accessToken = response.accessToken;
    if (response.idTokenClaims?.roles) {
      userRoles = response.idTokenClaims.roles;
      localStorage.setItem('roles', JSON.stringify(userRoles));
    }
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
