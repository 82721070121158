export default {
  parentType: {
    appRegistration: 'appRegistration',
    enterpriseApplication: 'enterpriseApplication',
    digiCert: 'digiCert',
    gitHubAccessToken: 'gitHubAccessToken',
    route53: 'route53',
    sectigoCert: 'sectigocert',
    others: 'others',
  },
  certificates: {
    appRegistration: {
      key: 'appRegistration',
      label: 'App Registration',
      iconFile: '/images/appRegistration.svg',
      pageUrl: '/certificates/appregistration',
    },
    enterpriseApplication: {
      key: 'enterpriseApplication',
      label: 'Enterprise Apps',
      iconFile: '/images/enterpriseApp.svg',
      pageUrl: '/certificates/enterprise',
    },
    digiCert: {
      label: 'DigiCert',
      iconFile: '/images/digiCert.svg',
      pageUrl: '/certificates/digicert',
    },
    route53: {
      label: 'Route53',
      iconFile: '/images/digiCert.svg',
      pageUrl: '/certificates/route53',
    },
    sectigocert: {
      label: 'SectigoCert',
      iconFile: '/images/digiCert.svg',
      pageUrl: '/certificates/sectigo',
    },
    others: {
      label: 'Others',
      iconFile: '/images/misc.svg',
      pageUrl: '/certificates/misc',
    },
    gitHubAccessToken: {
      label: 'Github Access Token',
      iconFile: '/images/misc.svg',
      pageUrl: '/keys/github',
    },
  },
};
