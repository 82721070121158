import React from 'react';
import PropTypes from 'prop-types';
import { loginRequest } from '../authConfig';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_REQUESTING':
      return {
        ...state, isAuthenticated: false, error: '', loading: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state, isAuthenticated: true, error: '', loading: false,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state, isAuthenticated: false, error: 'Invalid username or password', loading: false,
      };
    case 'SIGN_OUT_SUCCESS':
      return {
        ...state, isAuthenticated: false, error: '', loading: false,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('id_token'),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node,
};

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

function loginUser(dispatch, instance) {
  dispatch({ type: 'LOGIN_REQUESTING' });
  instance.loginRedirect(loginRequest);
}

function signOut(instance) {
  instance.logoutRedirect({
    account: instance.getActiveAccount(),
  });
}

export {
  UserProvider, useUserState, useUserDispatch, loginUser, signOut,
};
