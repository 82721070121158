import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  Tabs,
  Tab,
  Box,
  Divider,
  Grid,
  Button,
  Chip,
  Typography,
} from '@mui/material';
import constants from '../../utils/constants';
import SummaryTab from './summaryTab';
import ExternalContactForm from './externalContactForm';
import Route53CertConfigurationForm from './route53certconfigTab';
import JSONViewer from './jsonView';
import PrologisContactForm from './prologisContactForm';
import ClientSecretTab from './clientSecretsTab';

/* Azure App Registration Tabs */
import APIPermissions from './apiPermissionTab';
import BasicSAMLConfigurationTab from './basicSAMLConfigurationTab';

function TabPanel(props) {
  const {
    children,
    value,
    hashKey,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== hashKey}
      id={`certificate-tabpanel-${hashKey}`}
      aria-labelledby={`certificate-tab-${hashKey}`}
      {...other}
    >
      {value === hashKey && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  hashKey: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `azure-tab-${index}`,
    'aria-controls': `azure-tabpanel-${index}`,
  };
}

function CertificateTabs({
  source,
  certData,
  servicePrinicple,
  isNew,
  handleSummaryFieldChange,
  handleExternalSupportFieldChange,
  handleAddMoreExternalSupport,
  handleRemoveExternalContact,
  handlePrologisContactFieldChange,
  handleRoute53FieldChange,
  handleAddMorePrologisContact,
  handleRemovePrologisContactAtIndex,
  handleBasicSAMLConfigChange,
  handleClientSecretFieldChange,
}) {
  const location = useLocation();
  const [value, setValue] = React.useState(location?.hash || '#summary');

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const enableAzureTabs = () => source === constants.parentType.appRegistration 
    || source === constants.parentType.enterpriseApplication;

  const showAzureTabs = enableAzureTabs();

  return (
    <Box
      sx={{ width: '100%' }}
    >
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="Certificate Details Tabs"
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Summary" value="#summary" href="#summary" {...a11yProps(0)} />
        <Tab label="External Contact" value="#external-contact" href="#external-contact" {...a11yProps(1)} />
        <Tab label="Prologis Contact" value="#prologis-contact" href="#prologis-contact" {...a11yProps(2)} />
        {certData  && certData.issuer_commonName &&  (
        <Tab label="Certificate Issuer" value="#certificate-issuer" href="#certificate-issuer" {...a11yProps(3)} />
        )}
        {certData   &&  (
        <Tab label="Other Details" value="#json-viewer" href="#json-viewer" {...a11yProps(4)} />
        )}
        { source === constants.parentType.appRegistration && (<Tab
          label="API / Permission"
          value="#api-permissions"
          href="#api-permissions"
          {...a11yProps(3)}
        />)}
        { showAzureTabs && (<Tab
          label="Basic SAML Configuration"
          value="#saml-configuration"
          href="#saml-configuration"
          {...a11yProps(4)}
        />)}
        { showAzureTabs && (<Tab
          label="Client Secrets"
          value="#client-secrets"
          href="#client-secrets"
          {...a11yProps(5)}
        />)}
      </Tabs>
      <TabPanel value={value} hashKey="#summary">
        <Grid
          container
          spacing={2}
          justifyContent="center"
        >
          <Grid item md={7} xs={12}>
            <SummaryTab
              source={source}
              certData={certData}
              newCert={isNew}
              handleChange={handleSummaryFieldChange}
            />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} hashKey="#external-contact">
        <Grid
          container
          spacing={2}
          justifyContent="center"
        >
          <Grid item md={7} xs={12}>
            {certData?.externalContact?.map((supportData, index) => (
              <>
                <ExternalContactForm
                  supportContactData={supportData}
                  handleChange={handleExternalSupportFieldChange}
                  index={index}
                />
                <Divider sx={{ m: 3 }}>
                  <Chip
                    onClick={() => handleRemoveExternalContact(index)}
                    label="Remove above contact"
                  />
                </Divider>
              </>))}
          </Grid>
          <Grid item md={7} xs={12} textAlign="right" sx={{ paddingRight: 3 }}>
            <Button
              variant="outlined"
              onClick={handleAddMoreExternalSupport}
              size="small"
            >
              Add more contact person
            </Button>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} hashKey="#prologis-contact">
        <Grid
          container
          spacing={2}
          justifyContent="center"
        >
          <Grid item md={7} xs={12}>
            {certData?.prologisContact?.map((prologisContactData, index) => (
              <>
                <PrologisContactForm
                  prologisContactData={prologisContactData}
                  handleChange={handlePrologisContactFieldChange}
                  index={index}
                />
                <Divider sx={{ m: 3 }}>
                  <Chip
                    onClick={() => handleRemovePrologisContactAtIndex(index)}
                    label="Remove Above Contact"
                  />
                </Divider>
              </>))}
          </Grid>
          <Grid item md={7} xs={12} textAlign="right" sx={{ paddingRight: 3 }}>
            <Button
              variant="outlined"
              onClick={handleAddMorePrologisContact}
              size="small"
            >
              Add more contact person
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
        {certData  && certData.issuer_commonName &&  (
      <TabPanel value={value} hashKey="#certificate-issuer">
        <Grid
          container
          spacing={2}
          justifyContent="center"
        >
          <Grid item md={7} xs={12}>
            {
              <>
                <Route53CertConfigurationForm
                  Route53certissueData={certData}
                  handleChange={handleRoute53FieldChange}
                  index={1}
                />
              </>
            }
          </Grid>
        </Grid>
      </TabPanel>)}

      {certData  &&  (
      <TabPanel value={value} hashKey="#json-viewer">
        <Grid
          container
          spacing={2}
          justifyContent="center"
        >
          <Grid item md={7} xs={12}>
            {
              <>
                <JSONViewer
                  JSON_Data={certData}
                  handleChange={handleRoute53FieldChange}
                  index={1}
                />
              </>
            }
          </Grid>
        </Grid>
      </TabPanel>)}


      { showAzureTabs && (
      <TabPanel value={value} hashKey="#api-permissions">
        <Grid
          container
          spacing={2}
          justifyContent="center"
        >
          <Grid item md={9} xs={12}>
            <APIPermissions
              resources={certData?.data?.requiredResourceAccess}
            />
          </Grid>
        </Grid>
      </TabPanel>
      )}

      { showAzureTabs && (
      <TabPanel value={value} hashKey="#saml-configuration">
        <Grid
          container
          spacing={2}
          justifyContent="center"
        >
          <Grid item md={7} xs={12}>
            <BasicSAMLConfigurationTab
              source={source}
              certData={certData}
              newCert={isNew}
              handleChange={handleBasicSAMLConfigChange}
              servicePrinicple={servicePrinicple}
            />
          </Grid>
        </Grid>
      </TabPanel>
      )}
      { showAzureTabs && (
      <TabPanel value={value} hashKey="#client-secrets">
        <Grid
          container
          spacing={2}
          justifyContent="center"
        >
          <Grid item md={7} xs={12} sx={{ paddingLeft: 3 }}>
            <Typography variant="subtitle1">
              <b>Password Credentials</b>
            </Typography>
          </Grid>
          <Grid item md={7} xs={12}>
            {certData?.data?.passwordCredentials.map((clientSecretData, index) => (
              <>
                <ClientSecretTab
                  clientSecret={clientSecretData}
                  handleChange={handleClientSecretFieldChange}
                  index={index}
                />
                <Divider sx={{ m: 2 }} />
              </>))}
          </Grid>
          {/* <Grid item md={7} xs={12} textAlign="right" sx={{ paddingRight: 3 }}>
            <Button
              variant="outlined"
              onClick={handleAddMoreClientSecret}
              size="small"
            >
              Add more Client Secrets
            </Button>
          </Grid> */}
          <Grid item md={7} xs={12} sx={{ paddingLeft: 3 }}>
            <Typography variant="subtitle1">
              <b>Key Credentials</b>
            </Typography>
          </Grid>
          <Grid item md={7} xs={12}>
            {certData?.data?.keyCredentials.map((clientSecretData, index) => (
              <>
                <ClientSecretTab
                  clientSecret={clientSecretData}
                  handleChange={handleClientSecretFieldChange}
                  index={index}
                />
                <Divider sx={{ m: 2 }} />
              </>))}
          </Grid>
        </Grid>
      </TabPanel>
      )}
    </Box>
  );
}

CertificateTabs.propTypes = {
  source: PropTypes.oneOf(['App Registration', 'Enterprise Apps', 'Combined Apps', 'DigiCert', 'Others']).isRequired,
  certData: PropTypes.objectOf(PropTypes.object).isRequired,
  servicePrinicple: PropTypes.objectOf(PropTypes.object).isRequired,
  isNew: PropTypes.bool.isRequired,
  handleSummaryFieldChange: PropTypes.func.isRequired,
  handleExternalSupportFieldChange: PropTypes.func.isRequired,
  handleAddMoreExternalSupport: PropTypes.func.isRequired,
  handleRemoveExternalContact: PropTypes.func.isRequired,
  handlePrologisContactFieldChange: PropTypes.func.isRequired,
  handleRoute53FieldChange: PropTypes.func.isRequired,
  handleAddMorePrologisContact: PropTypes.func.isRequired,
  handleRemovePrologisContactAtIndex: PropTypes.func.isRequired,
  handleBasicSAMLConfigChange: PropTypes.func.isRequired,
  handleClientSecretFieldChange: PropTypes.func.isRequired,
};

export default CertificateTabs;
