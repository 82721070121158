import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Typography,
  Button,
  Link,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import TitleAction from './TitleAction';

const MainCard = forwardRef(
  (
    {
      children,
      title,
      iconUrl,
      iconRedirectUrl,
      redirectUrl,
      ...others
    },
    ref,
  ) => {
    const theme = useTheme();
    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: 'none',
          borderColor: theme.palette.primary.dark,
          ':hover': {
            boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
          },
        }}
      >
        {/* card header and action */}
        {title && (
        <CardHeader
          sx={{
            '& .MuiCardHeader-action': { mr: 0 },
            backgroundColor: theme.palette.primary.main,
          }}
          title={<Typography variant="h6">{title}</Typography>}
          action={<TitleAction imageSrc={iconUrl} redirectUrl={iconRedirectUrl} />}
        />
        )}

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        <CardContent
          sx={{
            backgroundColor: theme.palette.primary.light,
          }}
        >
          {children}
        </CardContent>

        {/* content & footet divider */}
        <Divider />
        <CardActions
          disableSpacing
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Link
            component={RouterLink}
            variant="body2"
            to={redirectUrl}
            sx={{
              width: '100%',
            }}
          >
            <Button size="small" fullWidth>
              <Typography
                variant="overline"
                color="text.white"
              >
                {`View All ${title}`}
              </Typography>
            </Button>
          </Link>
        </CardActions>
      </Card>
    );
  },
);

MainCard.propTypes = {
  title: PropTypes.string,
  iconUrl: PropTypes.string,
  iconRedirectUrl: PropTypes.string,
  redirectUrl: PropTypes.string,
  children: PropTypes.node,
};

export default MainCard;
