import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

function AccessToken({ location }) {
  return (
    <>
      <AuthenticatedTemplate>
        <Redirect
          to={{
            pathname: '/dashboard',
            state: {
              from: location,
            },
          }}
        />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location,
            },
          }}
        />
      </UnauthenticatedTemplate>
    </>
  );
}

AccessToken.propTypes = {
  location: PropTypes.string,
};

export default withRouter(AccessToken);
