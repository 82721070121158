import * as React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Button,
} from '@mui/material';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function DraggableDialog({
  title,
  children,
  open,
  setOpenState,
  handleSave,
  saveState,
}) {
  const saveForm = () => {
    handleSave();
  };

  const handleClose = () => {
    setOpenState();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton
            onClick={saveForm}
            loading={saveState}
            color="primary"
            size="small"
            disabled={!!saveState}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DraggableDialog.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  open: PropTypes.bool,
  setOpenState: PropTypes.func,
  handleSave: PropTypes.func,
  saveState: PropTypes.bool,
//   maxWidth: PropTypes.string,
};

export default DraggableDialog;
