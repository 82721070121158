import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import moment from 'moment';
import DataTable from '../../../components/DataTable';
import PageTitle from '../../../components/PageTitle';
import LinkCellRenderer from '../../../components/DataTable/LinkCellRenderer';
import axios from '../../../utils/axios';

function timeStampToDateTimeFormatter(params) {
  return moment(params.value, 'X').format('MM/DD/YYYY hh:mm A');
}

function timeStampToDateFormatter(params) {
  return moment(params.value, 'X').format('MM/DD/YYYY');
}

const columnDefs = [
  {
    field: 'key',
    headerName: 'ID',
    showDisabledCheckboxes: true,
    cellRenderer: (params) => (<LinkCellRenderer {...params} navigateUrl={`/keys/github/key/${params?.data?.key}`} />),
  },
  {
    headerName: 'Name',
    field: 'name',
  },
  {
    field: 'validity_expiry_date',
    headerName: 'Validity Expiry Date',
    valueFormatter: timeStampToDateFormatter,
  },
  {
    field: 'modified_timestamp',
    headerName: 'Last updated',
    valueFormatter: timeStampToDateTimeFormatter,
    sort: 'desc',
  },
  {
    headerName: 'Active',
    field: 'active',
    cellRenderer: (params) => (params.data?.active !== false ? <CheckIcon color="success" /> : <ClearIcon color="error" />),
  },
  {
    headerName: 'External Contact',
    children: [{ field: "ExternalContactName" ,headerName: 'Name'}, { field: "ExternalContactEmail" ,headerName: 'Email' }]
  },
  {
    headerName: 'Prologis Contact',
    children: [{ field: "PrologisContactName" ,headerName: 'Name',}, { field: "PrologisContactEmail",headerName: 'Email' }]
  },
];

function GithubAccessKeys({ history }) {
  const containerStyle = useMemo(() => ({ width: '100%', height: '75%' }), []);
  const [rowData, setRowData] = useState();

  const onGridReady = useCallback(() => {
    axios.get('/api/access-key/github')
      .then((response) => {
        response.data.forEach((el) => {
          if (el.externalContact) {
            el.externalContact.forEach((el1) => {
              if (el['ExternalContactName']) {
                el['ExternalContactName'] = el['ExternalContactName'] + ', ' + el1.name
              } else {
                el['ExternalContactName'] = el1.name
              }
              if (el['ExternalContactEmail']) {
                el['ExternalContactEmail'] = el['ExternalContactEmail'] + ', ' + el1.email
              } else {
                el['ExternalContactEmail'] = el1.email
              }

            })
          }
          if (el.prologisContact) {
            el.prologisContact.forEach((el1) => {
              if (el["PrologisContactName"]) {
                el['PrologisContactName'] = el['PrologisContactName'] + ', ' + el1.name
              } else {
                el['PrologisContactName'] = el1.name
              }
              if (el["PrologisContactEmail"]) {
                el['PrologisContactEmail'] = el['PrologisContactEmail'] + ', ' + el1.email
              } else {
                el['PrologisContactEmail'] = el1.email
              }

            })
          }
        });
        setRowData(response.data);
      });
  }, []);

  const navigateToAddNew = () => {
    history.push('/keys/github/key');
  };

  return (
    <>
      <PageTitle title="Github Access Tokens" actionButton="Add New" buttonOnClick={navigateToAddNew} />
      <div style={containerStyle}>
        <DataTable
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
        />
      </div>
    </>
  );
}

GithubAccessKeys.propTypes = {
  history: PropTypes.objectOf(PropTypes.object),
};

export default GithubAccessKeys;
