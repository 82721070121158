import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Divider,
  Chip,
  useTheme,
} from '@mui/material';
import _ from 'lodash';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withRouter } from 'react-router-dom';
import axios from '../../../../utils/axios';
import constants from '../../../../utils/constants';
import CertificateFormHeader from '../../../../components/CertificateFormHeader';
import CertificateHistory from '../../../../components/AuditLogs';
import CertificateTabs from '../../../../components/CertificateTabs';
import CertficateActionButtons from '../../../../components/CertificateActionButtons';

const defaultValues = {
  key: '',
  name: '',
  application_poc: '',
  externalContact: [{
    name: '',
    phone: '',
    email: '',
    notes: '',
  }],
  prologisContact: [{
    name: '',
    phone: '',
    email: '',
    notes: '',
  }],
};

function CreateDigiCertCertificates({ history, match }) {
  const theme = useTheme();
  const [breadcrumbs, setBreadCrumbs] = useState([
    {
      text: 'Certificates',
    },
    {
      text: 'DigiCert',
      href: '/certificates/digicert',
    },
  ]);

  const [loading, setLoading] = useState({});
  const [isNew, setIsNew] = useState(true);
  const [certData, setCertData] = useState({ ...defaultValues });

  const setCertificateStatus = () => {
    setLoading({ active: true });

    // if certificate active status is not set yet, than fist status will be consider as false
    // because default is true from Scheduler
    const postData = {
      ...certData,
      key: parseInt(certData.key, 10),
      active: 'active' in certData ? !certData.active : false,
    };

    axios.post('/api/certscanner/digicert', postData)
      .then((res) => {
        setCertData(res.data);
        setLoading({ active: false });
        toast.success(`Certificate status is set to ${res.data.active ? 'Active' : 'Inactive'}`);
      })
      .catch((err) => {
        console.log('Active status error', err);
        setLoading({ active: false });
        toast.error('An error occured while updating status.');
      });
  };

  const handleCancelClick = () => {
    history.push('/certificates/digicert');
  };

  const handleSave = () => {
    setLoading({ save: true });
    const serialized = { ...certData };
    serialized.key = parseInt(serialized.key, 10);
    axios.post('/api/certscanner/digicert', serialized)
      .then((res) => {
        setCertData(res.data);
        setLoading({ save: false });
        toast.success('Certificate saved successfully.');
      })
      .catch((err) => {
        console.log('Save error', err);
        setLoading({ save: false });
        toast.error('An error occured while Saving.');
      });
  };

  const handleDelete = () => {
    setLoading({ delete: true });
    axios.delete(`/api/certscanner/digicert/${match.params.key}`)
      .then(() => {
        setLoading({ delete: false });
        toast.warning('Certificate deleted successfully.');
        setTimeout(() => {
          history.push('/certificates/digicert');
        }, 3000);
      })
      .catch((err) => {
        console.log('Deletion error', err);
        setLoading({ delete: false });
        toast.error('An error occured while deleting.');
      });
  };

  useEffect(() => {
    if (!match?.params?.key) {
      setCertData({});
      setBreadCrumbs([...breadcrumbs, {
        text: 'New Certificate',
        href: '',
      }]);
    }
  }, [match.params.key]);

  useEffect(() => {
    if (match?.params?.key) {
      axios.get(`/api/certscanner/digicert/${match.params.key}`)
        .then((res) => {
          const responseData = res.data;
          if (!responseData
            || !responseData.externalContact
            || responseData.externalContact.length === 0) {
            /// if externalContact is not available, use default one
            responseData.externalContact = [];
            responseData.externalContact.push(defaultValues.externalContact[0]);
          }

          if (!responseData
            || !responseData.prologisContact
            || responseData.prologisContact.length === 0) {
            /// if prologisContact is not available, use default one
            responseData.prologisContact = [];
            responseData.prologisContact.push(defaultValues.prologisContact[0]);
          }

          setCertData(responseData);
          setIsNew(!res?.data?.key);
          if (res?.data?.name) {
            setBreadCrumbs([...breadcrumbs, {
              text: res?.data?.name,
              href: '',
            }]);
          }
        });
    } else {
      setBreadCrumbs([...breadcrumbs, {
        text: 'New',
        href: '',
      }]);
      setCertData({
        ...certData,
      });
    }
  }, []);

  const handleSummaryFieldChange = (fieldId, value) => {
    setCertData({
      ...certData,
      [fieldId]: value,
    });
  };

  const handleAddMoreExternalSupport = () => {
    const supportField = _.cloneDeep(certData.externalContact);
    supportField.push({
      ...defaultValues.externalContact[0],
    });
    setCertData({
      ...certData,
      externalContact: [...supportField],
    });
  };

  const handleRemoveExternalContactAtIndex = (index) => {
    const supportField = _.cloneDeep(certData.externalContact);
    supportField.splice(index, 1);
    setCertData({
      ...certData,
      externalContact: [...supportField],
    });
  };

  const handleExternalSupportFieldChange = (fieldId, value, insertAt) => {
    const supportField = _.cloneDeep(certData.externalContact);
    let newArray = [];
    if (supportField.length > 0 && insertAt < supportField.length) {
      newArray = supportField.map((item, i) => {
        if (insertAt === i) {
          return { ...item, [fieldId]: value };
        /* eslint-disable-next-line no-else-return */
        } else {
          return item;
        }
      });
    } else {
      newArray.push({
        ...defaultValues.externalContact[0],
        [fieldId]: value,
      });
    }

    setCertData({
      ...certData,
      externalContact: [...newArray],
    });
  };

  const handleAddMorePrologisContact = () => {
    const prologisContact = _.cloneDeep(certData.prologisContact);
    prologisContact.push({
      ...defaultValues.prologisContact[0],
    });
    setCertData({
      ...certData,
      prologisContact: [...prologisContact],
    });
  };

  const handleRemovePrologisContactAtIndex = (index) => {
    const prologisContact = _.cloneDeep(certData.prologisContact);
    prologisContact.splice(index, 1);
    setCertData({
      ...certData,
      prologisContact: [...prologisContact],
    });
  };

  const handlePrologisContactFieldChange = (fieldId, value, insertAt) => {
    const prologisContact = _.cloneDeep(certData.prologisContact);
    let newArray = [];
    if (prologisContact.length > 0 && insertAt < prologisContact.length) {
      newArray = prologisContact.map((item, i) => {
        if (insertAt === i) {
          return { ...item, [fieldId]: value };
        /* eslint-disable-next-line no-else-return */
        } else {
          return item;
        }
      });
    } else {
      newArray.push({
        ...defaultValues.prologisContact[0],
        [fieldId]: value,
      });
    }

    setCertData({
      ...certData,
      prologisContact: [...newArray],
    });
  };

  const handleActiveStatus = () => {
    setCertificateStatus();
  };

  const updateCertData = (updatedData) => {
    setCertData(updatedData);
  };

  return (
    <>
      <CertificateFormHeader
        certData={certData}
        breadCrumbs={breadcrumbs}
        onSave={handleSave}
        onCancel={handleCancelClick}
        onDelete={handleDelete}
        onChangeActive={handleActiveStatus}
        loading={loading}
      />
      <Grid
        container
        spacing={2}
        style={{ paddingTop: theme.spacing(2) }}
      >
        <Grid item xs={12}>
          <CertificateTabs
            source={constants.parentType.digiCert}
            certData={certData}
            isNew={isNew}
            handleSummaryFieldChange={handleSummaryFieldChange}
            handleExternalSupportFieldChange={handleExternalSupportFieldChange}
            handleAddMoreExternalSupport={handleAddMoreExternalSupport}
            handleRemoveExternalContact={handleRemoveExternalContactAtIndex}
            handleAddMorePrologisContact={handleAddMorePrologisContact}
            handleRemovePrologisContactAtIndex={handleRemovePrologisContactAtIndex}
            handlePrologisContactFieldChange={handlePrologisContactFieldChange}
          />
        </Grid>

        <Grid item xs={12}>
          <CertficateActionButtons
            source={constants.parentType.digiCert}
            certData={certData}
            loading={loading}
            onSave={handleSave}
            onChangeActive={handleActiveStatus}
            onDelete={handleDelete}
            onCancel={handleCancelClick}
            updateCertData={updateCertData}
          />
        </Grid>

        {match?.params?.key && (
          <>
            <Grid item xs={12} style={{ padding: '16px', paddingTop: '64px' }}>
              <Divider>
                <Chip label="Certificate updates" />
              </Divider>
            </Grid>

            <Grid item xs={12}>
              <CertificateHistory
                keyId={certData.key}
                entity="digi_cert"
              />
            </Grid>
          </>
        )}

      </Grid>
      <ToastContainer />
    </>
  );
}

CreateDigiCertCertificates.propTypes = {
  history: PropTypes.objectOf(PropTypes.object),
  match: PropTypes.objectOf(PropTypes.object),
};

export default withRouter(CreateDigiCertCertificates);
