import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Container,
} from '@mui/material';

function PrologisContactForm({ prologisContactData, handleChange, index }) {
  const handleTextFieldChange = (fieldId, value) => {
    handleChange(fieldId, value, index);
  };

  return (
    <Container>
      <Grid
        container
        spacing={0.5}
      >
        <Grid item xs={12}>
          <TextField
            value={prologisContactData?.name || ''}
            onChange={(e) => handleTextFieldChange('name', e.target.value)}
            margin="normal"
            label="Name"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={prologisContactData?.phone || ''}
            onChange={(e) => handleTextFieldChange('phone', e.target.value)}
            margin="normal"
            label="Phone"
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={prologisContactData?.email || ''}
            onChange={(e) => handleTextFieldChange('email', e.target.value)}
            margin="normal"
            label="Email"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={prologisContactData?.notes || ''}
            onChange={(e) => handleTextFieldChange('notes', e.target.value)}
            margin="normal"
            label="Notes"
            size="small"
            multiline
            rows={4}
            fullWidth
          />
        </Grid>
      </Grid>
    </Container>
  );
}

PrologisContactForm.propTypes = {
  prologisContactData: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default PrologisContactForm;
