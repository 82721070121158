import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import classnames from 'classnames';

// styles
import Header from '../Header/Header';
import Dashboard from '../../pages/dashboard/Dashboard';
import useStyles from './styles';

// components
import Sidebar from '../Sidebar';

// pages
import AppRegistrationCerts from '../../pages/certificates/app-registration';
import CreateAppRegistrationCert from '../../pages/certificates/app-registration/createForm';

import EnterpriseApps from '../../pages/certificates/enterprise';
import CreateEnterpriseAppsCert from '../../pages/certificates/enterprise/createForm';

import CombinedApps from '../../pages/certificates/combined';
import CreateCombinedAppsCert from '../../pages/certificates/combined/createForm';

import MiscCerts from '../../pages/certificates/misc-certificates';
import CreateMiscCerts from '../../pages/certificates/misc-certificates/createForm';

import DigiCerts from '../../pages/certificates/digi-cert';
import CreateDigiCerts from '../../pages/certificates/digi-cert/createForm';

import Route53 from '../../pages/certificates/route-53';
import CreateRoute53 from '../../pages/certificates/route-53/createForm';

import SectigoCert from '../../pages/certificates/sectigoCert';
import CreateSectigoCert from '../../pages/certificates/sectigoCert/createForm';

import GithubAccessKeys from '../../pages/keys/github';
import GithubAccessKeysForm from '../../pages/keys/github/createForm';

import ExpiringCertificates from '../../pages/expiringCertificates';

// context
import { useLayoutState } from '../../context/LayoutContext';

function Layout({ history }) {
  const classes = useStyles();
  // global
  const layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <Header history={history} />
      <Sidebar />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <Switch>
          <Route path="/dashboard" component={Dashboard} />

          <Route exact path="/certificates/appregistration" component={AppRegistrationCerts} />
          <Route exact path="/certificates/appregistration/cert" component={CreateAppRegistrationCert} />
          <Route exact path="/certificates/appregistration/cert/:key" component={CreateAppRegistrationCert} />

          <Route exact path="/certificates/enterprise" component={EnterpriseApps} />
          <Route exact path="/certificates/enterprise/cert" component={CreateEnterpriseAppsCert} />
          <Route exact path="/certificates/enterprise/cert/:key" component={CreateEnterpriseAppsCert} />

          <Route exact path="/certificates/combinedapps" component={CombinedApps} />
          <Route exact path="/certificates/combinedapps/cert" component={CreateCombinedAppsCert} />
          <Route exact path="/certificates/combinedapps/cert/:key" component={CreateCombinedAppsCert} />

          <Route exact path="/certificates/misc" component={MiscCerts} />
          <Route exact path="/certificates/misc/cert" component={CreateMiscCerts} />
          <Route exact path="/certificates/misc/cert/:key" component={CreateMiscCerts} />

          <Route exact path="/certificates/digicert" component={DigiCerts} />
          <Route exact path="/certificates/digicert/cert" component={CreateDigiCerts} />
          <Route exact path="/certificates/digicert/cert/:key" component={CreateDigiCerts} />

          <Route exact path="/certificates/sectigo" component={SectigoCert} />
          <Route exact path="/certificates/sectigo/cert" component={CreateSectigoCert} />
          <Route exact path="/certificates/sectigo/cert/:key" component={CreateSectigoCert} />

          <Route exact path="/certificates/route53" component={Route53} />
          <Route exact path="/certificates/route53/cert" component={CreateRoute53} />
          <Route exact path="/certificates/route53/cert/:key" component={CreateRoute53} />

          <Route exact path="/expiring/:type?/:startDate?/:endDate?" component={ExpiringCertificates} />

          <Route exact path="/keys/github" component={GithubAccessKeys} />
          <Route exact path="/keys/github/key" component={GithubAccessKeysForm} />
          <Route exact path="/keys/github/key/:key" component={GithubAccessKeysForm} />
        </Switch>
      </div>
    </div>
  );
}

Layout.propTypes = {
  history: PropTypes.objectOf(PropTypes.object),
};

export default withRouter(Layout);
