import React, { useMemo, useState,useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { MaterialReactTable } from 'material-react-table';
//Date Picker Imports
import {
  timeStampToDateTimeFormatter
} from '../../../components/DataTable/utils';
import PageTitle from '../../../components/PageTitle';
import LinkCellRenderer from '../../../components/DataTable/LinkCellRenderer';
import axios from '../../../utils/axios';



function Route53List({ history }) {
  const containerStyle = useMemo(() => ({ width: '100%', height: '75%' }), []);
  const [rowData, setRowData] = useState();

  useEffect(() => {
    axios.get('/api/certscanner/route53')
    .then((response) => {
      response.data.forEach((el) => {
        if (el.modified_timestamp) {
          el.modified_timestamp =  `${moment.unix(el.modified_timestamp).format('M/D/yyyy')}`
          console.log("el.modified_timestamp",el.modified_timestamp)
        }
      
        if (el.validity_expiry_date) {
          el.validity_expiry_date = timeStampToDateTimeFormatter({ value: el.validity_expiry_date })
          
        }
        if (el.serialNumber && el.issuer_commonName ) {
            el.serialNumber = el.issuer_commonName + ' - ' + el.serialNumber
        }
        else{
          el.serialNumber = "N/A"
        }
        if (el.externalContact) {
          el.externalContact.forEach((el1) => {
            if (el['ExternalContactName']) {
              el['ExternalContactName'] = el['ExternalContactName'] + ', ' + el1.name
            } else {
              el['ExternalContactName'] = el1.name
            }
            if (el['ExternalContactEmail']) {
              el['ExternalContactEmail'] = el['ExternalContactEmail'] + ', ' + el1.email
            } else {
              el['ExternalContactEmail'] = el1.email
            }

          })
        }
        if (el.prologisContact) {
          el.prologisContact.forEach((el1) => {
            if (el["PrologisContactName"]) {
              el['PrologisContactName'] = el['PrologisContactName'] + ', ' + el1.name
            } else {
              el['PrologisContactName'] = el1.name
            }
            if (el["PrologisContactEmail"]) {
              el['PrologisContactEmail'] = el['PrologisContactEmail'] + ', ' + el1.email
            } else {
              el['PrologisContactEmail'] = el1.email
            }

          })
        }
      })
      // console.log(response.data);
      setRowData(response.data);
    });
  }, []);


  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'key',
        Cell: ({ renderedCellValue }) => <LinkCellRenderer navigateUrl={`/certificates/route53/cert/${renderedCellValue}`} value={renderedCellValue} />,
        enableGrouping: false, //do not let this column be grouped
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableGrouping: false, //do not let this column be grouped
      },
      {
        accessorFn: (row) => row.validity_expiry_date? new Date(row.validity_expiry_date) :'', //convert to Date for sorting and filtering
        header: 'Validity Expiry Date',
        id: 'validity_expiry_date',
       // columnFilterModeOptions: ['lessThanOrEqualTo', 'greaterThanOrEqualTo', 'lessThan','greaterThan'],
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        enableGrouping: false, //do not let this column be grouped
        Cell: ({ cell }) => cell.getValue()?cell.getValue().toLocaleDateString():'', //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
              renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
            />
          </LocalizationProvider>
        ),

      },
      {
        accessorFn: (row) => new Date(row.modified_timestamp), //convert to Date for sorting and filtering
        id: 'modified_timestamp',
        header: 'Last updated',
        //columnFilterModeOptions: ['lessThanOrEqualTo', 'greaterThanOrEqualTo', 'lessThan','greaterThan'],
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        enableGrouping: false,
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
              renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
            />
          </LocalizationProvider>
        ),
      },

      {
        header: 'Certificate Issuer',
        accessorKey: 'serialNumber',
        enableGrouping: false, //do not let this column be grouped
      },
      {
        header: 'External Contact Name',
        enableGrouping: false, //do not let this column be grouped
        accessorKey: 'ExternalContactName',
      },
      {
        header: 'External Contact Email',
        enableGrouping: false, //do not let this column be grouped
        accessorKey: 'ExternalContactEmail',
      },{
        header: 'Prologis Contact Name',
        enableGrouping: false, //do not let this column be grouped
        accessorKey: 'PrologisContactName',
      },
      {
        header: 'Prologis Contact Email',
        enableGrouping: false, //do not let this column be grouped
        accessorKey: 'PrologisContactEmail',
      }
    ],
    [],
  );

  return (
    <>
      <PageTitle title="Route 53" />
      <div style={containerStyle}>
    <MaterialReactTable
      columns={columns}
      data={rowData || []}
      enableColumnResizing
      enableGrouping
      enableStickyHeader
      enableColumnFilterModes
      enableStickyFooter
      state={{ showProgressBars: true }} //or showSkeletons
      initialState={{
        density: 'compact',
        showColumnFilters: true,
        expanded: true, //expand all groups by default
        grouping: ['serialNumber'], //an array of columns to group by by default (can be multiple)
        pagination: { pageIndex: 0, pageSize: 200 },
        sorting: [{ id: 'serialNumber', desc: true },{id:"validity_expiry_date",desc:true}], //sort by state by default
        columnPinning: { left: ['key'] }
      }}
      muiToolbarAlertBannerChipProps={{ color: 'primary' }}
      enablePinning
      muiTableContainerProps={{  }}
    />
      </div>
    </>
  );
}

Route53List.propTypes = {
  history: PropTypes.objectOf(PropTypes.object),
};

export default Route53List;
