import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Container,
} from '@mui/material';
import moment from 'moment';
// import DateTimePicker from '../DateTimePicker';

function ClientSecretTab({ clientSecret, handleChange, index }) {
  const handleTextFieldChange = (fieldId, value) => {
    handleChange(fieldId, value, index);
  };

  return (
    <Container>
      <Grid
        container
        spacing={0.5}
      >
        <Grid item xs={12}>
          <TextField
            value={clientSecret?.displayName || clientSecret?.description || ''}
            onChange={(e) => handleTextFieldChange('description', e.target.value)}
            margin="normal"
            label={clientSecret?.displayName ? 'Display name' : 'Description'}
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={clientSecret.endDateTime ? moment(clientSecret.endDateTime).format('MM/DD/yyyy') : ''}
            onChange={(e) => handleTextFieldChange('expiring_date', e.target.value)}
            margin="normal"
            label="Expiring Date"
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ readOnly: true }}
          />
          {/* <DateTimePicker
            id="expiring_date"
            value={clientSecret.endDateTime ? moment(clientSecret.endDateTime).format('X') : ''}
            onChange={(e) => handleTextFieldChange('expiring_date', e)}
            margin="normal"
            label="Expiring Date"
            size="small"
            fullWidth
            disabled
          /> */}
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={clientSecret?.keyId || ''}
            onChange={(e) => handleTextFieldChange('secret_id', e.target.value)}
            margin="normal"
            label="Secret ID"
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </Grid>

        {clientSecret?.type && (
          <Grid item xs={12}>
            <TextField
              value={clientSecret?.type || ''}
              onChange={(e) => handleTextFieldChange('type', e.target.value)}
              margin="normal"
              label="Type"
              variant="outlined"
              size="small"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
        )}

        {clientSecret?.usage && (
          <Grid item xs={12}>
            <TextField
              value={clientSecret?.usage || ''}
              onChange={(e) => handleTextFieldChange('usage', e.target.value)}
              margin="normal"
              label="Usage"
              variant="outlined"
              size="small"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
        )}

      </Grid>
    </Container>
  );
}

ClientSecretTab.propTypes = {
  clientSecret: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default ClientSecretTab;
