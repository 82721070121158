import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

// styles
import useStyles from './styles';

// components
import { Typography } from '../Wrappers';

function PageTitle(props) {
  const classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h2" size="sm">
        {props.title}
      </Typography>
      {props.actionButton && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="primary"
          onClick={props.buttonOnClick}
        >
          {props.actionButton}
        </Button>
      )}
    </div>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string,
  actionButton: PropTypes.string,
  buttonOnClick: PropTypes.func,
};

export default PageTitle;
