import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

function LinkCellRenderer({ navigateUrl, value }) {
  // console.log("LinkCellRenderer", props)
  return (
    <Link
      component={RouterLink}
      variant="body2"
      to={navigateUrl}
    >
      {value}
    </Link>
  );
}

LinkCellRenderer.propTypes = {
  navigateUrl: PropTypes.string,
  value: PropTypes.string,
};

export default LinkCellRenderer;
