import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

function TitleAction({ redirectUrl, imageSrc, handleClick }) {
  const theme = useTheme();
  return (
    <RouterLink to={redirectUrl}>
      <Avatar
        variant="rounded"
        sx={{
          ...theme.typography.commonAvatar,
          ...theme.typography.mediumAvatar,
          backgroundColor: theme.palette.primary.main,
          zIndex: 1,
          content: `url("${imageSrc}")`,
        }}
        aria-controls="title-action-card"
        aria-haspopup="false"
        onClick={handleClick}
      />
    </RouterLink>
  );
}

TitleAction.propTypes = {
  redirectUrl: PropTypes.string,
  imageSrc: PropTypes.string,
  handleClick: PropTypes.func,
};

export default TitleAction;
