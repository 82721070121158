import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Container,
} from '@mui/material';

function JiraTicketForm({ summary, description, onChange }) {
  return (
    <Container>
      <Grid
        container
        spacing={0}
      >
        <Grid item xs={12}>
          <TextField
            value={summary}
            id="summary"
            onChange={(event) => onChange('summary', event.target.value)}
            margin="normal"
            label="Summary"
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={description}
            id="description"
            onChange={(event) => onChange('description', event.target.value)}
            margin="normal"
            label="Description"
            size="small"
            multiline
            rows={10}
            fullWidth
          />
        </Grid>
      </Grid>
    </Container>

  );
}

JiraTicketForm.propTypes = {
  summary: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
};

export default JiraTicketForm;
