import axios from 'axios';
import * as App from '../index';
import * as utils from './utils';

const instance = axios.create({
  baseURL: utils.getEndpoint(),
  timeout: 29000,
});

instance.interceptors.request.use((config) => {
  const { msalInstance } = App;
  const accounts = msalInstance.getAllAccounts();

  const accessTokenRequest = {
    scopes: ['user.read'],
    account: accounts[0],
  };

  return msalInstance
    .acquireTokenSilent(accessTokenRequest)
    .then((accessTokenResponse) => {
      // Acquire token silent success
      const { accessToken } = accessTokenResponse;
      // Call your API with token
      /* eslint-disable-next-line no-param-reassign */
      config.headers.auth = `Bearer ${accessToken}`;
      return Promise.resolve(config);
    }).catch((error) => {
      console.error('Auth error', error);
      window.location.href = '/logout';
    });
}, (error) => Promise.reject(error));

instance.interceptors.response.use((response) => {
  if (response.status === 401) {
    // Clear local storage, redirect back to login
    window.location.href = '/logout';
  }
  return response;
}, (error) => Promise.reject(error));

export default instance;
