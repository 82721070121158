import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useUserDispatch } from '../context/UserContext';

function Logout({ history }) {
  const userDispatch = useUserDispatch();

  useEffect(() => {
    userDispatch({ type: 'SIGN_OUT_SUCCESS' });
    localStorage.clear();
    history.push('/login');
  }, []);

  return (
    <div />
  );
}

Logout.propTypes = {
  history: PropTypes.objectOf(PropTypes.object),
};

export default withRouter(Logout);
