import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography as TypographyBase,
  Button as ButtonBase,
  useTheme,
} from '@mui/material';

function getColor(color, theme, brigtness = 'main') {
  if (color && theme.palette[color] && theme.palette[color][brigtness]) {
    return theme.palette[color][brigtness];
  }

  return '';
}

function getFontWeight(style) {
  switch (style) {
    case 'light':
      return 300;
    case 'medium':
      return 500;
    case 'bold':
      return 600;
    default:
      return 400;
  }
}

function getFontSize(size, theme, variant = '') {
  let multiplier;

  switch (size) {
    case 'sm':
      multiplier = 0.8;
      break;
    case 'md':
      multiplier = 1.5;
      break;
    case 'xl':
      multiplier = 2;
      break;
    case 'xxl':
      multiplier = 3;
      break;
    default:
      multiplier = 1;
      break;
  }

  const defaultSize = variant && theme.typography[variant]
    ? theme.typography[variant].fontSize
    : `${theme.typography.fontSize}px`;

  return `calc(${defaultSize} * ${multiplier})`;
}

function createStyled(styles, options) {
  /* eslint-disable-next-line func-names */
  const Styled = function (props) {
    const { children, ...other } = props;
    return children(other);
  };

  return withStyles(styles, options)(Styled);
}
function Typography({
  children,
  weight,
  size,
  colorBrightness,
  color,
  variant,
  ...props
}) {
  const theme = useTheme();

  return (
    <TypographyBase
      style={{
        color: getColor(color, theme, colorBrightness),
        fontWeight: getFontWeight(weight),
        fontSize: getFontSize(size, theme, variant),
      }}
      {...props}
    >
      {children}
    </TypographyBase>
  );
}

Typography.propTypes = {
  children: PropTypes.node,
  weight: PropTypes.string,
  size: PropTypes.string,
  colorBrightness: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
};

function Button({ children, color, ...props }) {
  const theme = useTheme();

  const Styled = createStyled({
    button: {
      backgroundColor: getColor(color, theme),
      boxShadow: theme.customShadows.widget,
      color: 'white',
      '&:hover': {
        backgroundColor: getColor(color, theme, 'light'),
        boxShadow: theme.customShadows.widgetWide,
      },
    },
  });

  return (
    <Styled>
      {({ classes }) => (
        <ButtonBase classes={{ root: classes.button }} {...props}>
          {children}
        </ButtonBase>
      )}
    </Styled>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
};

export { Typography, Button };
