import React, {
  useEffect, useMemo, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, LinearProgress, Button, Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

import axios from '../../utils/axios';
import DataTable from '../DataTable';

function timeStampToDateFormatter(params) {
  return moment(params.value, 'X').format('MM/DD/YYYY hh:mm A');
}

const columnDefs = [
  { field: 'user', headerName: 'User' },
  // { field: 'access_type', headerName: 'Access Type' },
  { field: 'operation', headerName: 'Action' },
  { field: 'field', headerName: 'Field Changed' },
  { field: 'old_value', headerName: 'Old Value' },
  { field: 'new_value', headerName: 'New Value' },
  {
    field: 'timestamp',
    headerName: 'Date',
    valueFormatter: timeStampToDateFormatter,
    sort: 'desc',
  },
];

function CertificateHistory({ keyId, entity }) {
  const containerStyle = useMemo(() => ({ width: '100%', height: '500px' }), []);
  const [rowData, setRowData] = useState();
  const [loading, setLoading] = useState(false);
  const [expanded, setAccordianExpanded] = useState(false);

  const diffValue = {
    type_changes: 'Updated',
    values_changed: 'Updated',
    iterable_item_removed: 'Removed',
    dictionary_item_added: 'Added',
    dictionary_item_removed: 'Removed',
    iterable_item_added: 'Added',
  };

  const onGridReady = useCallback(() => {

  }, []);

  const formattedValue = (value) => {
    const dateTimeField = moment(value, 'X', true).isValid();
    if (dateTimeField) {
      return moment(value, 'X').format('MM/DD/YYYY');
    }

    return value;
  };

  const onAccordianStateChange = (e, newValue) => {
    setAccordianExpanded(newValue);
  };

  function tryParseJSONObject(jsonString) {
    try {
      if (typeof jsonString === 'object') return jsonString;

      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  const processData = (auditData) => {
    if (!auditData) {
      setRowData([]);
      return;
    }

    const dataArray = [];

    for (const auditDataKey in auditData) {
      const fieldValue = auditData[auditDataKey];

      if (!fieldValue) continue;

      const entryObject = {
        user: fieldValue.user,
        access_type: fieldValue.access_type,
        timestamp: fieldValue.timestamp,
        operation: 'New record created',
      };

      if (!fieldValue.data) {
        dataArray.push(entryObject);
        continue;
      }

      for (const accessOperation in fieldValue?.data) {
        const accessOperationData = tryParseJSONObject(fieldValue.data[accessOperation]);

        if (!accessOperationData) {
          dataArray.push({
            ...entryObject,
            operation: diffValue[accessOperation] || accessOperation,
            field: fieldValue.data[accessOperation],
            old_value: '',
            new_value: '',
          });
          continue;
        }

        for (const accessOpDataField in accessOperationData) {
          if (!accessOperationData[accessOpDataField]) continue;

          const accessOpDataValue = accessOperationData[accessOpDataField];
          /// / Lets check if data is timestamp

          dataArray.push({
            ...entryObject,
            operation: diffValue[accessOperation] || accessOperation,
            field: accessOpDataField,
            old_value: formattedValue(accessOpDataValue.old_value),
            new_value: formattedValue(accessOpDataValue.new_value),
          });
        }
      }
    }

    setRowData(dataArray);
  };

  const fetchData = () => {
    setLoading(true);
    const fetchUrl = `/api/certscanner/auditlogs?key=${keyId}&entity=${entity}`;

    axios.get(fetchUrl)
      .then((response) => {
        processData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshData = (e) => {
    e.stopPropagation();
    fetchData();
  };

  useEffect(() => {
    if (expanded) {
      fetchData();
    }
  }, [expanded]);

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={onAccordianStateChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Change logs</Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={refreshData}
              style={{ position: 'absolute', right: '50px' }}
            >
              Refresh
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {loading && <LinearProgress />}
          <div style={containerStyle}>
            <DataTable
              rowData={rowData}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

CertificateHistory.propTypes = {
  keyId: PropTypes.string,
  entity: PropTypes.string,
};

export default CertificateHistory;
