import React from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';

import { MsalProvider } from '@azure/msal-react';

import Themes from './themes';

import { LayoutProvider } from './context/LayoutContext';
import { UserProvider } from './context/UserContext';

import Routes from './Routes';

function App({ pca }) {
  // global
  return (
    <MsalProvider instance={pca}>
      <LayoutProvider>
        <UserProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={Themes.default}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </StyledEngineProvider>
        </UserProvider>
      </LayoutProvider>
    </MsalProvider>
  );
}

App.propTypes = {
  pca: PropTypes.objectOf(PropTypes.object),
};

export default App;
