import React, {
  useMemo, useRef, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';

function AgGridTable({ columnDefs, rowData, onGridReady }) {
  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: '90vh', width: '100%' }), []);
  const [gridData, setGridData] = useState();
  const [colFormat] = useState(columnDefs);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    minWidth: 100,
    // make every column use 'text' filter by default
    filter: 'agTextColumnFilter',
    // enable floating filters by default
    floatingFilter: true,
    // make columns resizable
    resizable: true,
    sortable: true,
    cellStyle: { fontSize: '13px' },
  }), []);

  useEffect(() => {
    setGridData(rowData);
  }, [rowData]);

  return (
    <div style={gridStyle} className="ag-theme-alpine">
      <AgGridReact
        ref={gridRef}
        rowData={gridData}
        columnDefs={colFormat}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        suppressRowClickSelection
        onGridReady={onGridReady}
        suppressDragLeaveHidesColumns
        pagination
        paginationPageSize={25}
        enableCellTextSelection
        // groupDisplayType={groupDisplayType}
        // groupRowRenderer={groupRowRenderer}
      />
    </div>
  );
}

AgGridTable.propTypes = {
  columnDefs: PropTypes.instanceOf(Array),
  rowData: PropTypes.instanceOf(Array),
  onGridReady: PropTypes.func,
};

export default AgGridTable;
