import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Container,
  CircularProgress,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import moment from 'moment';
import DataTable from '../../components/DataTable';
import LinkCellRenderer from '../../components/DataTable/LinkCellRenderer';
import DateTimePicker from '../../components/DateTimePicker';
import axios from '../../utils/axios';
import constants from '../../utils/constants';

function parentTypeFomatter(params) {
  if (params.value === constants.parentType.appRegistration) return 'App Registration';
  if (params.value === constants.parentType.enterpriseApplication) return 'Enterprise Application';
  if (params.value === constants.parentType.digiCert) return 'DigiCert';
  if (params.value === constants.parentType.route53) return 'Route53';
  if (params.value === constants.parentType.sectigoCert) return 'SectigoCert';
  return 'Other';
}

function expirableItemTypeFomatter(params) {
  if (params.value === 'passwordCredential') return 'Password';
  if (params.value === 'keyCredential') return 'Key';
  return 'Other';
}

function expiryDateFormatter(params) {
  return moment(params.value, 'X').format('MM/DD/YYYY');
}

function cellRendererFunction(params) {
  let navigateUrl = '/certificates';
  if (params?.data?.metadata?.parentType === constants.parentType.appRegistration) {
    navigateUrl += '/appregistration';
  } else if (params?.data?.metadata?.parentType === constants.parentType.enterpriseApplication) {
    navigateUrl += '/enterprise';
  } else if (params?.data?.metadata?.parentType === constants.parentType.digiCert) {
    navigateUrl += '/digicert';
  } else if (params?.data?.metadata?.parentType === constants.parentType.route53) {
    navigateUrl += '/route53';
  } else if (params?.data?.metadata?.parentType === constants.parentType.sectigoCert) {
    navigateUrl += '/sectigo';  
  } else if (params?.data?.metadata?.parentType === constants.parentType.others) {
    navigateUrl += '/misc';
  }

  navigateUrl += `/cert/${params?.data?.metadata?.appId}`;
  return (<LinkCellRenderer {...params} navigateUrl={navigateUrl} />);
}

function ExpiringCertificates({ history, match }) {
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%', minHeight: '450px' }), []);
  const [rowData, setRowData] = useState();

  const [loading, setLoading] = useState(false);
  const [certData, setCertData] = useState({});

  const [pageLoadSuccess, setPageLoadSuccess] = useState(false);

  const columnDefs = [
    {
      field: 'metadata.appId',
      headerName: 'Application ID',
      cellRenderer: cellRendererFunction,
    },
    {
      headerName: 'App Name',
      field: 'metadata.displayName',
    },
    {
      field: 'metadata.parentType',
      headerName: 'Provider',
      valueFormatter: parentTypeFomatter,
    },
    {
      field: 'metadata.expirableItemType',
      headerName: 'Item Type',
      valueFormatter: expirableItemTypeFomatter,
    },
    {
      field: 'metadata.expiryOn',
      headerName: 'Expiry date',
      valueFormatter: expiryDateFormatter,
      sort: 'asc',
    },
    {
      headerName: 'External Contact',
      children: [{ field: "ExternalContactName" ,headerName: 'Name'}, { field: "ExternalContactEmail" ,headerName: 'Email' }]
    },
    {
      headerName: 'Prologis Contact',
      children: [{ field: "PrologisContactName" ,headerName: 'Name',}, { field: "PrologisContactEmail",headerName: 'Email' }]
    },
  ];

  const fetchData = () => {
    setRowData();
    setLoading(true);
    let fetchUrl = '/api/certscanner/expirableitems?1=1';

    if (certData.certificate_provider && certData.certificate_provider !== '_all') {
      fetchUrl += `&parentItemType=${certData.certificate_provider}`;

      if (certData.certificate_provider === constants.parentType.appRegistration
      || certData.certificate_provider === constants.parentType.enterpriseApplication) {
        if (certData.expirableItemType && certData.expirableItemType !== 'all') {
          fetchUrl += `&expirableItemType=${certData.expirableItemType}`;
        }
      }
    }

    if (certData?.expiring_after) {
      fetchUrl += `&startDateTime=${certData.expiring_after}`;
    }

    if (certData?.expiring_before) {
      fetchUrl += `&endDateTime=${certData.expiring_before}`;
    }

    axios.get(fetchUrl)
      .then((response) => {
        setRowData(response.data);
        response.data.forEach((el) => {
          // debugger
          if (el.externalContact && el.externalContact.length > 0) {
            el.externalContact.forEach((el1) => {
              if (el['ExternalContactName']) {
                el['ExternalContactName'] = el['ExternalContactName'] + ', ' + el1.name
              } else {
                el['ExternalContactName'] = el1.name
              }
              if (el['ExternalContactEmail']) {
                el['ExternalContactEmail'] = el['ExternalContactEmail'] + ', ' + el1.email
              } else {
                el['ExternalContactEmail'] = el1.email
              }

            })
          }
          if (el.prologisContact && el.prologisContact.length > 0) {
            el.prologisContact.forEach((el1) => {
              if (el["PrologisContactName"]) {
                el['PrologisContactName'] = el['PrologisContactName'] + ', ' + el1.name
              } else {
                el['PrologisContactName'] = el1.name
              }
              if (el["PrologisContactEmail"]) {
                el['PrologisContactEmail'] = el['PrologisContactEmail'] + ', ' + el1.email
              } else {
                el['PrologisContactEmail'] = el1.email
              }

            })
          }
        })
        sessionStorage.certData = JSON.stringify(certData);
        sessionStorage.rowData = JSON.stringify(response.data);
      })
      .finally(() => {
        setLoading(false);
        setPageLoadSuccess(false);
      });
  };

  const onGridReady = useCallback(() => {

  }, []);

  const handleTextFieldChange = (fieldId, value) => {
    setCertData({
      ...certData,
      [fieldId]: value,
    });
  };

  const handleResetClick = () => {
    history.push('/expiring');
    setCertData({
      certificate_provider: '',
      expiring_after: '',
      expiring_before: '',
    });
    setPageLoadSuccess(true);
  };

  const handleApplyFilterClick = () => {
    setPageLoadSuccess(true);
  };

  useEffect(() => {
    if (pageLoadSuccess) fetchData();
  }, [pageLoadSuccess]);

  // useEffect(() => {
  //   let startDate = moment().subtract(15, 'd').format('X');
  //   let endDate = moment().add(30, 'd').format('X');

  //   if (match?.params?.startDate) startDate = match.params.startDate;
  //   if (match?.params?.endDate) endDate = match.params.endDate;

  //   setCertData({
  //     ...certData,
  //     certificate_provider: match?.params?.type,
  //     expiring_after: startDate,
  //     expiring_before: endDate,
  //   });
  //   setPageLoadSuccess(true);
  // }, [history.location]);

  useEffect(() => {
    if (sessionStorage.certData && sessionStorage.certData !== '{}') {
      setCertData(JSON.parse(sessionStorage.certData));
      setRowData(JSON.parse(sessionStorage.rowData || '[]'));
    } else if (match?.params?.startDate && match?.params?.endDate) {
      setCertData({
        ...certData,
        certificate_provider: match?.params?.type,
        expiring_after: match.params.startDate,
        expiring_before: match.params.endDate,
      });
      setPageLoadSuccess(true);
    } else {
      const startDate = moment().subtract(15, 'd').format('X');
      const endDate = moment().add(30, 'd').format('X');

      setCertData({
        ...certData,
        certificate_provider: match?.params?.type,
        expiring_after: startDate,
        expiring_before: endDate,
      });
      setPageLoadSuccess(true);
    }
  }, []);

  return (
    <Container maxWidth="lg" disableGutters>
      <Grid container spacing={3} style={{ minHeight: '80vh' }}>
        <Grid item md={3} sm={12} xs={12}>
          <Grid
            container
            direction="column"
          >
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
                Expiring Certificates
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mb: 1 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="certificate-provider">Certificate Provider</InputLabel>
                <Select
                  labelId="certificate-provider"
                  id="certificate-provider-select"
                  value={certData.certificate_provider || ''}
                  label="Certificate Provider"
                  onChange={(e) => handleTextFieldChange('certificate_provider', e.target.value)}
                >
                  {
                                      Object.keys(constants.certificates).map((key) => (
                                        <MenuItem key={`menu-item-source-${key}`} value={key}>{constants.certificates[key].label}</MenuItem>
                                      ))
                                    }
                  <MenuItem value="_all">
                    <em>All</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {(certData.certificate_provider === constants.parentType.appRegistration
                || certData.certificate_provider === constants.parentType.enterpriseApplication)
              && (
              <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="expirable-item-type">Expirable item type</InputLabel>
                  <Select
                    labelId="expirable-item-type"
                    id="expirable-item-type-select"
                    value={certData.expirableItemType || ''}
                    label="Expirable item type"
                    onChange={(e) => handleTextFieldChange('expirableItemType', e.target.value)}
                  >
                    <MenuItem value="keyCredentials">
                      Key Credentials
                    </MenuItem>
                    <MenuItem value="passwordCredentials">
                      Password Credentials
                    </MenuItem>
                    <MenuItem value="all">
                      <em>All</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              )}
            <Grid item xs={12}>
              <DateTimePicker
                id="expiring_after"
                value={certData.expiring_after || ''}
                onChange={(e) => handleTextFieldChange('expiring_after', e)}
                margin="normal"
                label="Creds expiring after"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sx={{ mb: 2 }}>
              <DateTimePicker
                id="expiring_before"
                value={certData.expiring_before || ''}
                onChange={(e) => handleTextFieldChange('expiring_before', e)}
                margin="normal"
                label="Creds expiring before"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
              >
                <Grid item xs={12}>
                  <Button
                    onClick={handleApplyFilterClick}
                    variant="contained"
                    color="primary"
                    size="small"
                    fullWidth
                  >
                    {loading && <CircularProgress size={14} sx={{ mr: 0.5, color: '#fff' }} />}
                    {' '}
                    Apply Filters
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button onClick={handleResetClick} variant="contained" color="secondary" size="small" fullWidth>
                    Reset Filters
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={9} sm={12} xs={12}>
          <div style={containerStyle}>
            <DataTable
              rowData={rowData}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

ExpiringCertificates.propTypes = {
  history: PropTypes.objectOf(PropTypes.object),
  match: PropTypes.objectOf(PropTypes.object),
};

export default ExpiringCertificates;
