import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../../components/DataTable';
import {
  timeStampToDateFormatter,
  timeStampToDateTimeFormatter,
  timeStampToDateComporator,
  booleanFieldValueGetter,
  booleanCellRenderer,
} from '../../../components/DataTable/utils';
import PageTitle from '../../../components/PageTitle';
import LinkCellRenderer from '../../../components/DataTable/LinkCellRenderer';
import axios from '../../../utils/axios';

const columnDefs = [
  {
    field: 'key',
    headerName: 'Application ID',
    showDisabledCheckboxes: true,
    cellRenderer: (params) => (<LinkCellRenderer {...params} navigateUrl={`/certificates/misc/cert/${params?.data?.key}`} />),
  },
  {
    headerName: 'Name',
    field: 'name',
  },
  {
    field: 'validity_expiry_date',
    headerName: 'Validity Expiry Date',
    valueFormatter: timeStampToDateFormatter,
    filter: 'agDateColumnFilter',
    filterParams: {
      suppressAndOrCondition: true,
      comparator: timeStampToDateComporator,
    },
  },
  {
    field: 'modified_timestamp',
    headerName: 'Last updated',
    valueFormatter: timeStampToDateTimeFormatter,
    filter: 'agDateColumnFilter',
    filterParams: {
      suppressAndOrCondition: true,
      comparator: timeStampToDateComporator,
    },
    sort: 'desc',
  },
  {
    headerName: 'Active',
    field: 'active',
    valueFormatter: booleanCellRenderer,
    valueGetter: booleanFieldValueGetter,
    filterParams: {
      suppressAndOrCondition: true,
      valueFormatter: booleanCellRenderer,
      valueGetter: booleanFieldValueGetter,
    },
  },
  {
    headerName: 'External Contact',
    children: [{ field: "ExternalContactName" ,headerName: 'Name'}, { field: "ExternalContactEmail" ,headerName: 'Email' }]
  },
  {
    headerName: 'Prologis Contact',
    children: [{ field: "PrologisContactName" ,headerName: 'Name',}, { field: "PrologisContactEmail",headerName: 'Email' }]
  },
];

function AgGridTable({ history }) {
  const containerStyle = useMemo(() => ({ width: '100%', height: '75%' }), []);
  const [rowData, setRowData] = useState();

  const onGridReady = useCallback(() => {
    axios.get('/api/certscanner/misc')
      .then((response) => {
        response.data.forEach((el) => {
          if (el.externalContact) {
            el.externalContact.forEach((el1) => {
              if (el['ExternalContactName']) {
                el['ExternalContactName'] = el['ExternalContactName'] + ', ' + el1.name
              } else {
                el['ExternalContactName'] = el1.name
              }
              if (el['ExternalContactEmail']) {
                el['ExternalContactEmail'] = el['ExternalContactEmail'] + ', ' + el1.email
              } else {
                el['ExternalContactEmail'] = el1.email
              }

            })
          }
          if (el.prologisContact) {
            el.prologisContact.forEach((el1) => {
              if (el["PrologisContactName"]) {
                el['PrologisContactName'] = el['PrologisContactName'] + ', ' + el1.name
              } else {
                el['PrologisContactName'] = el1.name
              }
              if (el["PrologisContactEmail"]) {
                el['PrologisContactEmail'] = el['PrologisContactEmail'] + ', ' + el1.email
              } else {
                el['PrologisContactEmail'] = el1.email
              }

            })
          }
        })
        setRowData(response.data);
      });
  }, []);

  const navigateToAddNew = () => {
    history.push('/certificates/misc/cert');
  };

  return (
    <>
      <PageTitle title="Other Certificates" actionButton="Add New" buttonOnClick={navigateToAddNew} />
      <div style={containerStyle}>
        <DataTable
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
        />
      </div>
    </>
  );
}

AgGridTable.propTypes = {
  history: PropTypes.objectOf(PropTypes.object),
};

export default AgGridTable;
