import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view' 
import {
  Grid,
  Container,
} from '@mui/material';

function JSONViewer({ JSON_Data, handleChange, index }) {

  return (
    <Container>
      <Grid
        container
        spacing={0.5}
      >
        <ReactJson src={JSON_Data.data ? JSON_Data.data : JSON_Data}></ReactJson>

      </Grid>
    </Container>
  );
}

JSONViewer.propTypes = {
  JSON_Data: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default JSONViewer;
