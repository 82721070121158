import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Divider,
  Chip,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withRouter } from 'react-router-dom';
import axios from '../../../../utils/axios';
import constants from '../../../../utils/constants';
import CertificateFormHeader from '../../../../components/CertificateFormHeader';
import CertificateHistory from '../../../../components/AuditLogs';
import CertificateTabs from '../../../../components/CertificateTabs';
import CertficateActionButtons from '../../../../components/CertificateActionButtons';
import DirectoryAuditLogs from '../../../../components/DirectoryAuditLogs';

const defaultValues = {
  key: '',
  name: '',
  application_poc: '',
  saml_config: {
    identifier: '',
    logout_url: '',
    relay_state: '',
    reply_url: '',
    sign_on_url: '',
  },
  externalContact: [{
    name: '',
    phone: '',
    email: '',
    notes: '',
  }],
  prologisContact: [{
    name: '',
    phone: '',
    email: '',
    notes: '',
  }],
  client_secrets: [{
    description: '',
    expiring_date: '',
    secret_id: '',
  }],
};

function CreateAppRegistration({ history, match }) {
  const [breadcrumbs, setBreadCrumbs] = useState([
    {
      text: 'Certificates',
    },
    {
      text: 'App Registration',
      href: '/certificates/appregistration',
    },
  ]);
  const theme = useTheme();
  const [loading, setLoading] = useState({});
  const [isNew, setIsNew] = useState(true);
  const [certData, setCertData] = useState({ ...defaultValues });
  const [servicePrinciple, setServicePrinciple] = useState(null);

  const handleCancelClick = () => {
    history.push('/certificates/appregistration');
  };

  const getServicePrinciple = () => {
    axios.get(`/api/certscanner/enterpriseapps/${match.params.key}`)
      .then((res) => {
        setServicePrinciple(res.data);
      });
  };

  const handleSave = () => {
    setLoading({ save: true });
    axios.post('/api/certscanner/appregistrations', certData)
      .then((res) => {
        setCertData(res.data);
        setLoading({ save: false });
        toast.success('Certificate saved successfully.');
      })
      .catch((err) => {
        console.log('Save error', err);
        setLoading({ save: false });
        toast.error('An error occured while Saving.');
      });
  };

  const setCertificateStatus = () => {
    setLoading({ active: true });

    const postData = {
      ...certData,
      active: 'active' in certData ? !certData.active : false,
    };

    axios.post('/api/certscanner/appregistrations', postData)
      .then((res) => {
        setCertData(res.data);
        setLoading({ active: false });
        toast.success(`Certificate status is set to ${res.data.active ? 'Active' : 'Inactive'}`);
      })
      .catch((err) => {
        console.log('Active status error', err);
        setLoading({ active: false });
        toast.error('An error occured while updating status.');
      });
  };

  const handleDelete = () => {
    setLoading({ delete: true });
    axios.delete(`/api/certscanner/appregistrations/${match.params.key}`)
      .then(() => {
        setLoading({ delete: false });
        toast.warning('Certificate deleted successfully.');
        setTimeout(() => {
          history.push('/certificates/appregistration');
        }, 3000);
      })
      .catch((err) => {
        console.log('Deletion error', err);
        setLoading({ delete: false });
        toast.error('An error occured while deleting.');
      });
  };

  useEffect(() => {
    if (!match?.params?.key) {
      setCertData({});
      setBreadCrumbs([...breadcrumbs, {
        text: 'New Certificate',
        href: '',
      }]);
    }
  }, [match.params.key]);

  useEffect(() => {
    if (match?.params?.key) {
      axios.get(`/api/certscanner/appregistrations/${match.params.key}`)
        .then((res) => {
          const responseData = res.data;
          if (!responseData
            || !responseData.externalContact
            || responseData.externalContact.length === 0) {
            /// if externalContact is not available, use default one
            responseData.externalContact = [];
            responseData.externalContact.push(defaultValues.externalContact[0]);
          }

          if (!responseData
            || !responseData.prologisContact
            || responseData.prologisContact.length === 0) {
            /// if prologisContact is not available, use default one
            responseData.prologisContact = [];
            responseData.prologisContact.push(defaultValues.prologisContact[0]);
          }

          if (!responseData
            || !responseData.client_secrets
            || responseData.client_secrets.length === 0) {
            /// if prologisContact is not available, use default one
            responseData.client_secrets = [];
            responseData.client_secrets.push(defaultValues.client_secrets[0]);
          }

          setCertData(responseData);
          setIsNew(!res?.data?.key);
          getServicePrinciple();

          if (res?.data?.name) {
            setBreadCrumbs([...breadcrumbs, {
              text: res?.data?.name,
              href: '',
            }]);
          }
        });
    } else {
      setBreadCrumbs([...breadcrumbs, {
        text: 'New',
        href: '',
      }]);
      setCertData({
        ...certData,
      });
    }
  }, []);

  const handleSummaryFieldChange = (fieldId, value) => {
    setCertData({
      ...certData,
      [fieldId]: value,
    });
  };

  const handleBasicSAMLConfigChange = (fieldId, value) => {
    setCertData({
      ...certData,
      saml_config: {
        ...certData.saml_config,
        [fieldId]: value,
      },
    });
  };

  const handleAddMoreExternalSupport = () => {
    const supportField = _.cloneDeep(certData.externalContact);
    supportField.push({
      ...defaultValues.externalContact[0],
    });
    setCertData({
      ...certData,
      externalContact: [...supportField],
    });
  };

  const handleRemoveExternalContactAtIndex = (index) => {
    const supportField = _.cloneDeep(certData.externalContact);
    supportField.splice(index, 1);
    setCertData({
      ...certData,
      externalContact: [...supportField],
    });
  };

  const handleExternalSupportFieldChange = (fieldId, value, insertAt) => {
    const supportField = _.cloneDeep(certData.externalContact);
    let newArray = [];
    if (supportField.length > 0 && insertAt < supportField.length) {
      newArray = supportField.map((item, i) => {
        if (insertAt === i) {
          return { ...item, [fieldId]: value };
        /* eslint-disable-next-line no-else-return */
        } else {
          return item;
        }
      });
    } else {
      newArray.push({
        ...defaultValues.externalContact[0],
        [fieldId]: value,
      });
    }

    setCertData({
      ...certData,
      externalContact: [...newArray],
    });
  };

  const handleAddMorePrologisContact = () => {
    const prologisContact = _.cloneDeep(certData.prologisContact);
    prologisContact.push({
      ...defaultValues.prologisContact[0],
    });
    setCertData({
      ...certData,
      prologisContact: [...prologisContact],
    });
  };

  const handleRemovePrologisContactAtIndex = (index) => {
    const prologisContact = _.cloneDeep(certData.prologisContact);
    prologisContact.splice(index, 1);
    setCertData({
      ...certData,
      prologisContact: [...prologisContact],
    });
  };

  const handlePrologisContactFieldChange = (fieldId, value, insertAt) => {
    const prologisContact = _.cloneDeep(certData.prologisContact);
    let newArray = [];
    if (prologisContact.length > 0 && insertAt < prologisContact.length) {
      newArray = prologisContact.map((item, i) => {
        if (insertAt === i) {
          return { ...item, [fieldId]: value };
        /* eslint-disable-next-line no-else-return */
        } else {
          return item;
        }
      });
    } else {
      newArray.push({
        ...defaultValues.prologisContact[0],
        [fieldId]: value,
      });
    }

    setCertData({
      ...certData,
      prologisContact: [...newArray],
    });
  };

  const handleAddMoreClientSecret = () => {
    const clientSecretArray = _.cloneDeep(certData.client_secrets);
    clientSecretArray.push({
      ...defaultValues.client_secrets[0],
    });
    setCertData({
      ...certData,
      client_secrets: [...clientSecretArray],
    });
  };

  const handleRemoveClientSecretAtIndex = (index) => {
    const clientSecretArray = _.cloneDeep(certData.client_secrets);
    clientSecretArray.splice(index, 1);
    setCertData({
      ...certData,
      client_secrets: [...clientSecretArray],
    });
  };

  const handleClientSecretFieldChange = (fieldId, value, insertAt) => {
    const clientSecretArray = _.cloneDeep(certData.client_secrets);
    let newArray = [];
    if (clientSecretArray.length > 0 && insertAt < clientSecretArray.length) {
      newArray = clientSecretArray.map((item, i) => {
        if (insertAt === i) {
          return { ...item, [fieldId]: value };
        /* eslint-disable-next-line no-else-return */
        } else {
          return item;
        }
      });
    } else {
      newArray.push({
        ...defaultValues.client_secrets[0],
        [fieldId]: value,
      });
    }

    setCertData({
      ...certData,
      client_secrets: [...newArray],
    });
  };

  const handleActiveStatus = () => {
    setCertificateStatus();
  };

  const updateCertData = (updatedData) => {
    setCertData(updatedData);
  };

  const azureRedirectUrl = `https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/${certData.key}`;

  return (
    <>
      <CertificateFormHeader
        breadCrumbs={breadcrumbs}
        azureRedirectUrl={certData.key ? azureRedirectUrl : ''}
      />
      <Grid
        container
        spacing={2}
        style={{ paddingTop: theme.spacing(2) }}
      >
        <Grid item xs={12}>
          <CertificateTabs
            source={constants.parentType.appRegistration}
            certData={certData}
            servicePrinicple={servicePrinciple?.data}
            isNew={isNew}
            handleSummaryFieldChange={handleSummaryFieldChange}
            handleExternalSupportFieldChange={handleExternalSupportFieldChange}
            handleAddMoreExternalSupport={handleAddMoreExternalSupport}
            handleRemoveExternalContact={handleRemoveExternalContactAtIndex}
            handleAddMorePrologisContact={handleAddMorePrologisContact}
            handleRemovePrologisContactAtIndex={handleRemovePrologisContactAtIndex}
            handlePrologisContactFieldChange={handlePrologisContactFieldChange}
            handleBasicSAMLConfigChange={handleBasicSAMLConfigChange}
            handleAddMoreClientSecret={handleAddMoreClientSecret}
            handleRemoveClientSecretAtIndex={handleRemoveClientSecretAtIndex}
            handleClientSecretFieldChange={handleClientSecretFieldChange}
          />
        </Grid>

        <Grid item xs={12}>
          <CertficateActionButtons
            source={constants.parentType.appRegistration}
            certData={certData}
            loading={loading}
            onSave={handleSave}
            onChangeActive={handleActiveStatus}
            onDelete={handleDelete}
            onCancel={handleCancelClick}
            certPortalRedirectURL={certData.key ? azureRedirectUrl : ''}
            updateCertData={updateCertData}
          />
        </Grid>

        {match?.params?.key && (
          <>
            <Grid item xs={12} style={{ padding: '16px', paddingTop: '64px' }}>
              <Divider>
                <Chip label="Certificate updates" />
              </Divider>
            </Grid>

            {certData?.directory_audit && (
              <Grid item xs={12}>
                <DirectoryAuditLogs auditHistory={certData?.directory_audit} />
              </Grid>
            )}

            <Grid item xs={12}>
              <CertificateHistory
                keyId={certData.key}
                entity="app_registration"
              />
            </Grid>
          </>
        )}

      </Grid>
      <ToastContainer />
    </>
  );
}

CreateAppRegistration.propTypes = {
  history: PropTypes.objectOf(PropTypes.object),
  match: PropTypes.objectOf(PropTypes.object),
};

export default withRouter(CreateAppRegistration);
