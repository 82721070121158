import { LogLevel } from '@azure/msal-browser';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

// Config object to be passed to Msal on creation
const commonConfig = {
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            // console.info(message);
            break;
          case LogLevel.Verbose:
            // console.debug(message);
            break;
          case LogLevel.Warning:
            // console.warn(message);
            break;
          default:
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/2cf835d1-453b-472b-9b90-3f6d854ad75b/me',
};

export function msalConfig() {
  const localHostMsalConfig = {
    ...commonConfig,
    auth: {
      clientId: '56c21dd9-7892-42c5-aff5-ae79c69ea466',
      authority: 'https://login.microsoftonline.com/2cf835d1-453b-472b-9b90-3f6d854ad75b',
      redirectUri: 'http://localhost:3000/accesstoken',
      postLogoutRedirectUri: 'http://localhost:3000/logout',
      navigateToLoginRequestUrl: false,
    },
  };

  const devMsalConfig = {
    ...commonConfig,
    auth: {
      clientId: '56c21dd9-7892-42c5-aff5-ae79c69ea466',
      authority: 'https://login.microsoftonline.com/2cf835d1-453b-472b-9b90-3f6d854ad75b',
      redirectUri: 'https://dev.sslcertscanner.prologisweb.com/accesstoken',
      postLogoutRedirectUri: 'https://dev.sslcertscanner.prologisweb.com/logout',
      navigateToLoginRequestUrl: false,
    },
  };

  const prodMsalConfig = {
    ...commonConfig,
    auth: {
      clientId: '94b259b6-cb79-4696-8ae0-8eac5f9919c2',
      authority: 'https://login.microsoftonline.com/2cf835d1-453b-472b-9b90-3f6d854ad75b',
      redirectUri: 'https://sslcertscanner.prologisweb.com/accesstoken',
      postLogoutRedirectUri: 'https://sslcertscanner.prologisweb.com/logout',
      navigateToLoginRequestUrl: false,
    },
  };

  if (window.location.hostname.toLowerCase().startsWith('localhost')) {
    return localHostMsalConfig;
  }

  if (window.location.hostname.toLowerCase() === 'dev.sslcertscanner.prologisweb.com') {
    return devMsalConfig;
  }

  if (window.location.hostname.toLowerCase() === 'sslcertscanner.prologisweb.com') {
    return prodMsalConfig;
  }

  return localHostMsalConfig;
}
