import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Container,
} from '@mui/material';

function Route53CertConfigurationForm({ Route53certissueData, handleChange, index }) {
  const handleTextFieldChange = (fieldId, value) => {
    handleChange(fieldId, value, index);
  };

  return (
    <Container>
      <Grid
        container
        spacing={0.5}
      >
        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.issuer_commonName || ''}
            onChange={(e) => handleTextFieldChange('issuer_commonName', e.target.value)}
            margin="normal"
            label="Issuer Name"
            variant="outlined"
            size="small"
            fullWidth
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.issuer_countryName || ''}
            onChange={(e) => handleTextFieldChange('issuer_countryName', e.target.value)}
            margin="normal"
            label="Issuer country Name"
            size="small"
            fullWidth
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.issuer_organizationName || ''}
            onChange={(e) => handleTextFieldChange('issuer_organizationName', e.target.value)}
            margin="normal"
            label="Issuer Organization Name"
            variant="outlined"
            size="small"
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.issuer_stateOrProvinceName || ''}
            onChange={(e) => handleTextFieldChange('issuer_stateOrProvinceName', e.target.value)}
            margin="normal"
            label="Issuer State Or Province Name"
            variant="outlined"
            size="small"
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.issuer_localityName || ''}
            onChange={(e) => handleTextFieldChange('issuer_localityName', e.target.value)}
            margin="normal"
            label="Issuer Locality Name"
            variant="outlined"
            size="small"
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.subject_commonName || ''}
            onChange={(e) => handleTextFieldChange('subject_commonName', e.target.value)}
            margin="normal"
            label="Subject Common Name"
            variant="outlined"
            size="small"
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.subject_countryName || ''}
            onChange={(e) => handleTextFieldChange('subject_countryName', e.target.value)}
            margin="normal"
            label="Subject Country Name"
            variant="outlined"
            size="small"
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.subject_localityName || ''}
            onChange={(e) => handleTextFieldChange('subject_localityName', e.target.value)}
            margin="normal"
            label="Subject Locality Name"
            variant="outlined"
            size="small"
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.subject_organizationName || ''}
            onChange={(e) => handleTextFieldChange('subject_organizationName', e.target.value)}
            margin="normal"
            label="Subject Organization Name"
            variant="outlined"
            size="small"
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.subject_stateOrProvinceName || ''}
            onChange={(e) => handleTextFieldChange('subject_stateOrProvinceName', e.target.value)}
            margin="normal"
            label="Subject State Or Province Name"
            variant="outlined"
            size="small"
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.subjectAltName || ''}
            onChange={(e) => handleTextFieldChange('subjectAltName', e.target.value)}
            margin="normal"
            label="Subject Alt Name"
            variant="outlined"
            size="small"
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Route53certissueData?.serialNumber || ''}
            onChange={(e) => handleTextFieldChange('serialNumber', e.target.value)}
            margin="normal"
            label="Serial Number"
            variant="outlined"
            size="small"
            disabled
            fullWidth
          />
        </Grid>



      </Grid>
    </Container>
  );
}

Route53CertConfigurationForm.propTypes = {
  Route53certissueData: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default Route53CertConfigurationForm;
