import moment from 'moment';

function numberFormatter(params) {
  return params.value ? parseInt(params.value, 10) : 0;
}

function stringToNumberComparator(value1, value2) {
  const val1 = value1 ? parseInt(value1, 10) : 0;
  const val2 = value2 ? parseInt(value2, 10) : 0;
  return val1 - val2;
}

function timeStampToDateTimeFormatter(params) {
  return params.value ? moment(params.value, 'X').format('MM/DD/YYYY hh:mm A') : '';
}

function timeStampToDateFormatter(params) {
  return params.value ? moment(params.value, 'X').format('MM/DD/YYYY') : '';
}

function timeStampToDateComporator(date, cellValue) {
  if (!cellValue) {
    return 0;
  }

  const cellDate = moment(cellValue, 'X').toDate();
  let retVal = 0;
  if (moment(cellDate).isBefore(date, 'day')) {
    retVal = -1;
  } else if (moment(cellDate).isAfter(date, 'day')) {
    retVal = 1;
  }

  return retVal;
}

function booleanCellRenderer(params) {
  if (!params.data || params.data.active === undefined) return '';
  
  return (
    params.data?.active !== false
    ? 'Yes'
    : 'No');
}

function booleanFieldValueGetter(params) {
  if (!params.data || params.data.active === undefined) return '';

  return params.data?.active !== false ? 'Yes' : 'No';
}

function booleanFieldCompartor(selected, cellValue) {
  if (!cellValue) {
    return 0;
  }

  return selected === 'Yes' ? -1 : 1;
}

export {
  numberFormatter,
  stringToNumberComparator,
  timeStampToDateTimeFormatter,
  timeStampToDateFormatter,
  timeStampToDateComporator,
  booleanFieldCompartor,
  booleanCellRenderer,
  booleanFieldValueGetter,
};
