import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import {
  Breadcrumbs as MUIBreadCrumbs,
  Link,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useStyles from './styles';

function BreadCrumbs({ links }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const breadCrumbLinks = links.map((link, index) => (
    <Link
      component={RouterLink}
      underline="hover"
      key={`${link.text}`}
      color={index === links.length - 1 ? 'text.primary' : 'inherit'}
      to={link.href ? link.href : '#'}
    >
      {link.text}
    </Link>
  ));

  return (
    <div className={classes.pageTitleContainer}>
      <MUIBreadCrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        maxItems={isMobile ? 2 : 4}
      >
        {breadCrumbLinks}
      </MUIBreadCrumbs>
    </div>
  );
}

BreadCrumbs.propTypes = {
  links: PropTypes.instanceOf(Array),
};

export default BreadCrumbs;
