import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  Link,
  useScrollTrigger,
  Slide,
  CssBaseline,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

// Msal imports
import { useMsal } from '@azure/msal-react';
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from '../../authConfig';

// styles

// components
import { Typography } from '../Wrappers';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { signOut } from '../../context/UserContext';
import { callMsGraph } from '../../utils/utils';
import useStyles from './styles';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Header() {
  const classes = useStyles();

  // global
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  const [profileMenu, setProfileMenu] = useState(null);
  const [isSearchOpen, setSearchOpen] = useState(false);

  /// temp
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph().then((response) => setGraphData(response)).catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            ...loginRequest,
            account: instance.getActiveAccount(),
          });
        }
      });
    }
  }, [inProgress, graphData, instance]);

  return (
    <>
      <CssBaseline />
      <HideOnScroll>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              onClick={() => toggleSidebar(layoutDispatch)}
              className={classNames(
                classes.headerMenuButton,
                classes.headerMenuButtonCollapse,
              )}
              size="large"
            >
              {layoutState.isSidebarOpened ? (
                <ArrowBackIcon
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse,
                    ),
                  }}
                />
              ) : (
                <MenuIcon
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse,
                    ),
                  }}
                />
              )}
            </IconButton>
            <Typography variant="body2" weight="medium" className={classes.logotype}>
              <Link component={RouterLink} to="/dashboard">
                <img alt="logo" className={classes.logotypeImage} />
              </Link>
            </Typography>
            <div className={classes.grow} />
            <div
              className={classNames(classes.search, {
                [classes.searchFocused]: isSearchOpen,
              })}
            >
              {/* eslint-disable jsx-a11y/click-events-have-key-events */}
              {/* eslint-disable jsx-a11y/no-static-element-interactions */}
              <div
                className={classNames(classes.searchIcon, {
                  [classes.searchIconOpened]: isSearchOpen,
                })}
                onClick={() => setSearchOpen(!isSearchOpen)}
              >
                <SearchIcon classes={{ root: classes.headerIcon }} />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
            <IconButton
              aria-haspopup="true"
              color="inherit"
              className={classes.headerMenuButton}
              aria-controls="profile-menu"
              onClick={(e) => setProfileMenu(e.currentTarget)}
              size="large"
            >
              <AccountIcon classes={{ root: classes.headerIcon }} />
            </IconButton>
            <Menu
              id="profile-menu"
              open={Boolean(profileMenu)}
              anchorEl={profileMenu}
              onClose={() => setProfileMenu(null)}
              className={classes.headerMenu}
              classes={{ paper: classes.profileMenu }}
              disableAutoFocusItem
            >
              <div className={classes.profileMenuUser}>
                <Typography variant="h4" weight="medium">
                  {graphData?.displayName}
                </Typography>
                <Typography
                  className={classes.profileMenuLink}
                  color="primary"
                >
                  {graphData?.jobTitle}
                </Typography>
              </div>
              <div className={classes.profileMenuUser}>
                <Typography
                  className={classes.profileMenuLink}
                  color="primary"
                  onClick={() => signOut(instance)}
                >
                  Sign Out
                </Typography>
              </div>
            </Menu>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
}
