import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box, Grid, Typography, Link,
} from '@mui/material';
import moment from 'moment';

// third-party
import Chart from 'react-apexcharts';

// project imports
import constants from '../../../utils/constants';
import LoaderCard from '../../../components/Cards/Skeleton/LoaderCard';

import CardWrapper from './styles';

const chartData = {
  series: [{
    name: 'Expiring',
    data: [],
  }],
  options: {
    chart: {
      height: 90,
      type: 'line',
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      zoom: {
        autoScaleYaxis: true,
      },
    },
    markers: {
      size: 3,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',

      labels: {
        show: false,
      },
    },
    yaxis: {
      // min: 0,
      // max: 50,
      // categories: [5, 10, 15, 20, 25],
      // axisBorder: {
      //     show: false,
      // }
      labels: {
        show: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#FDD835'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      theme: 'dark',
      fixed: {
        enabled: false,
      },
      x: {
        format: 'dd MMM yyyy',
      },
      y: {
        formatter(value) {
          return `${value}`;
        },
      },
      marker: {
        show: false,
      },
    },
    noData: {
      text: 'None expiring in 30 days',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#fff',
        fontSize: '14px',
        fontFamily: undefined,
      },
    },
  },
};

function CertificatesSummary({ isLoading = false, type = 'others', expirableItems = [] }) {
  const startDate = moment().format('X');
  const dateAfter30Days = moment().add(30, 'days').format('X');
  const dateAfter365Days = moment().add(365, 'days').format('X');
  const [certDetails, setCertDetails] = useState({});
  const chatConfig = _.cloneDeep(chartData);

  const formatData = () => {
    const filtered = expirableItems.filter((obj) => obj?.metadata?.parentType?.toLowerCase()
      === type.toLowerCase());

    const filterForNext30Days = filtered.filter((obj) => startDate < obj?.metadata?.expiryOn
      && obj?.metadata?.expiryOn < dateAfter30Days);

    const filterForNextOneYear = filtered.filter((obj) => startDate < obj?.metadata?.expiryOn
      && obj?.metadata?.expiryOn < dateAfter365Days);

    let groupOnDate = filterForNextOneYear.reduce((h, obj) => {
      const dateString = moment(obj?.metadata?.expiryOn, 'X').format('YYYY-MM-DD');
      const endDate = new Date(dateString).getTime();
      /* eslint-disable-next-line no-param-reassign */
      h[endDate] = (h[endDate] || []).concat(obj);
      return h;
    }, {});

    groupOnDate = _(groupOnDate).toPairs().sortBy(0).take(20)
      .fromPairs()
      .value();

    const chartPlotting = Object.keys(groupOnDate).map((key) => [
      parseInt(key, 10),
      groupOnDate[key].length,
    ]);

    chatConfig.series[0].data = chartPlotting;

    setCertDetails({
      total: filtered,
      expiring: filterForNext30Days,
      chartData: chatConfig,
    });
  };

  useEffect(() => {
    formatData();
  }, [expirableItems]);

  return (
    <>
      {isLoading ? (
        <LoaderCard />
      ) : (
        <CardWrapper
          title={constants.certificates[type].label}
          iconUrl={constants.certificates[type].iconFile}
          redirectUrl={constants.certificates[type].pageUrl}
          iconRedirectUrl={`/expiring/${type}`}
        >
          <Box>
            <Grid container direction="column" style={{ minHeight: '210px' }}>
              <Grid item>
                <Link
                  component={RouterLink}
                  underline="hover"
                  to={`/expiring/${type}`}
                  sx={{
                    color: '#fff',
                  }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography
                        sx={{
                          fontWeight: 300,
                        }}
                      >
                        All Expirable Items:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        {certDetails?.total?.length}
                      </Typography>
                    </Grid>

                  </Grid>
                </Link>
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between" style={{ minHeight: '23px' }}>
                  {certDetails?.expiring?.length > 0
                                        && (
                                        <Grid item>
                                          <Link
                                            component={RouterLink}
                                            underline="hover"
                                            to={`/expiring/${type}/${startDate}/${dateAfter30Days}`}
                                            sx={{
                                              color: '#fff',
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontWeight: 300,
                                                mt: 1.75,
                                              }}
                                              variant="caption"
                                            >
                                              {certDetails?.expiring?.length}
                                              {' '}
                                              certificate
                                              {certDetails?.expiring?.length > 0 ? 's' : ''}
                                              {' '}
                                              expiring in 30 days
                                            </Typography>
                                          </Link>
                                        </Grid>
                                        )}
                  {/* <Grid item>
                                        <Typography
                                            sx={{
                                                fontWeight: 700,
                                                mt: 1.75,
                                            }}>
                                                {certDetails?.expiring?.length}
                                        </Typography>
                                    </Grid> */}
                </Grid>
              </Grid>

              <Grid item sx={{ mb: 0.75, mt: 2.5 }}>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <Chart {...certDetails?.chartData} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
}

CertificatesSummary.propTypes = {
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  expirableItems: PropTypes.instanceOf(Array),
};

export default CertificatesSummary;
