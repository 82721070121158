import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
// import _ from 'lodash';
import axios from '../../utils/axios';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(true);

  const getResourceAccessName = (resourceAccessId) => {
    const resouceAccess = row.data.appRoles.find((e) => e.id === resourceAccessId);
    return resouceAccess?.value || '';
  };

  const getAllowedMemberType = (resourceAccessId) => {
    const resouceAccess = row.data.appRoles.find((e) => e.id === resourceAccessId);
    return resouceAccess?.allowedMemberTypes[0] || '';
  };

  const getAdminConsent = (resourceAccessId) => {
    const resouceAccess = row.data.appRoles.find((e) => e.id === resourceAccessId);
    return resouceAccess?.isEnabled ? 'Yes' : 'No';
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" colSpan={3}>
          <Typography variant="subtitle2" color="primary">
            {`${row?.data?.appDisplayName} (${props?.resourceAccess?.resourceAccess.length})`}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow style={{ height: 'min-content' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {props?.resourceAccess?.resourceAccess.map((resourceAccess) => (
                    <TableRow key={resourceAccess.id}>
                      <TableCell component="th" scope="row" width="270px">
                        <Typography variant="body2">
                          {getResourceAccessName(resourceAccess.id)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {getAllowedMemberType(resourceAccess.id)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {getAdminConsent(resourceAccess.id)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    key: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
  }).isRequired,
  resourceAccess: PropTypes.objectOf(PropTypes.array).isRequired,
};

function APIPermissionTab({ resources }) {
  const [resourcesData, setResourceData] = useState([]);

  const getResoucesDetails = async (resourceAccess) => {
    try {
      const response = await axios.get(`/api/certscanner/enterpriseapps/${resourceAccess.resourceAppId}`);
      const { data } = await response;
      return data;
    } catch (e) {
      return {};
    }
  };

  const getResourceAccess = (appId) => resources.find((e) => e.resourceAppId === appId);

  useEffect(() => {
    if (resources && resources.length > 0) {
      const resultArray = [];
      for (const resourceAccess of resources) {
        getResoucesDetails(resourceAccess)
          .then((result) => {
            resultArray.push({
              ...result,
            });
            setResourceData([...resultArray]);
          });
      }
    }
  }, [resources]);

  return (
    <TableContainer>
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell width="300px"><b>API / Permission Name</b></TableCell>
            <TableCell><b>Type</b></TableCell>
            <TableCell><b>Admin Consent</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resourcesData.map((row) => (
            <Row key={row.key} row={row} resourceAccess={getResourceAccess(row.key)} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

APIPermissionTab.propTypes = {
  resources: PropTypes.object.isRequired,
};

export default APIPermissionTab;
