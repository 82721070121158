/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Divider,
  useTheme,
  useMediaQuery,
  Tooltip,
  Typography,
  Breadcrumbs,
  Button,
} from '@mui/material';

// import { blue } from '@mui/material/colors';
import { LoadingButton } from '@mui/lab';
import {
  Delete as DeleteIcon,
  Save as SaveIcon,
  ArrowBack as ArrowBackIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment';
import config from '../../config.json';
import Prompt from '../ConfirmAlert';
import DraggableDialog from '../MUIDialog';
import JiraTicketForm from '../CreateJiraIssueForm';
import certConstants from '../../utils/constants';

import axios from '../../utils/axios';

function CertficateActionButtons({
  source,
  onSave,
  onChangeActive,
  onDelete,
  onCancel,
  certData,
  loading,
  certPortalRedirectURL,
  updateCertData,
}) {
  const theme = useTheme();
  const certInactive = 'active' in certData && certData.active === false;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [jiraModelOpen, setJiraModelOpen] = React.useState(false);
  const [savingJiraTicket, setSaveJiraTicketState] = React.useState(false);

  const [serviceNowModelOpen, setServiceNowModelOpen] = React.useState(false);
  const [savingServiceNowTicket, setSaveServiceNowTicketState] = React.useState(false);

  const confirmDelete = () => {
    Prompt('Delete', `Do you want to delete ${certData.name}?`, onDelete);
  };

  const confirmStatusChange = () => {
    Prompt('Changing status?', `Do you want to change status to ${certInactive ? 'Active' : 'Inactive'}?`, onChangeActive);
  };

  const actionButtonDisabled = () => {
    const roleString = localStorage.getItem('roles');
    const roles = roleString ? JSON.parse(roleString) : [];
    const intersectingAdminRoles = config.adminRoles.filter((el) => roles.indexOf(el) !== -1);
    return intersectingAdminRoles.length === 0;
  };

  const getTicketTitle = () => {
    if (!certData.key) return '';

    let returnValue = 'Expiring Credentials';
    console.log('source', source);
    if (source) {
      returnValue += ` - ${certConstants.certificates[source]?.label}`;
    }

    if (certData.name) {
      returnValue += ` - ${certData.name}`;
    }

    if (certData.validity_expiry_date) {
      returnValue += ` - ${moment.unix(certData.validity_expiry_date).format('MM/DD/yyyy')}`;
    }

    return returnValue;
  };

  const getTicketDescription = () => {
    if (!certData.key) return '';

    let returnValue = 'Expiring Credentials\n\n';

    if (source) {
      returnValue += `Source - ${certConstants.certificates[source]?.label}\n`;
    }

    if (certData?.key) {
      returnValue += `App ID - ${certData.key}\n`;
    }

    if (certData?.name) {
      returnValue += `Name - ${certData.name}\n`;
    }

    if (certData?.validity_expiry_date) {
      returnValue += `Expiring on - ${moment.unix(certData.validity_expiry_date).format('MM/DD/yyyy')}`;
    }

    returnValue += `\n\nView Details (SSL Portal) - ${window.location.href}`;

    if (certPortalRedirectURL) {
      returnValue += `\n\nView Details (${certConstants.certificates[source]?.label}) - ${certPortalRedirectURL}\n`;
    }

    return returnValue;
  };

  const [jiraTicketData, setJiraTicketData] = React.useState({
    summary: '',
    description: '',
  });

  const [serviceNowTicketData, setServiceNowTicketData] = React.useState({
    summary: '',
    description: '',
  });

  React.useEffect(() => {
    setJiraTicketData({
      summary: getTicketTitle(),
      description: getTicketDescription(),
    });

    setServiceNowTicketData({
      summary: getTicketTitle(),
      description: getTicketDescription(),
    });
  }, [certData, certPortalRedirectURL]);

  const jiraTicketFormValueChange = (key, value) => {
    setJiraTicketData({
      ...jiraTicketData,
      [key]: value,
    });
  };

  const serviceNowTicketFormValueChange = (key, value) => {
    setServiceNowTicketData({
      ...serviceNowTicketData,
      [key]: value,
    });
  };

  const saveJiraTicket = () => {
    setSaveJiraTicketState(true);
    console.log('jiraTicketData', jiraTicketData);
    axios.post('/api/certscanner/jira', {
      ...jiraTicketData,
      entity: source,
      key: certData.key,
    })
      .then((res) => {
        console.log('res', res);
        setSaveJiraTicketState(false);
        setJiraModelOpen(false);
        toast.success('JIRA Issue created successfully');
        updateCertData(res.data);
      })
      .catch((err) => {
        console.log('JIRA ticket create error', err);
        setSaveJiraTicketState(false);
        toast.error('An error occured while creating JIRA Issue.');
      });
  };

  const saveServiceNowTicket = () => {
    setSaveServiceNowTicketState(true);
    console.log('serviceNowTicketData', serviceNowTicketData);
    axios.post('/api/certscanner/servicenow', {
      ...serviceNowTicketData,
      entity: source,
      key: certData.key,
    })
      .then((res) => {
        console.log('res', res);
        setSaveServiceNowTicketState(false);
        setServiceNowModelOpen(false);
        toast.success('ServiceNow Issue created successfully');
        updateCertData(res.data);
      })
      .catch((err) => {
        console.log('ServiceNow ticket create error', err);
        setSaveServiceNowTicketState(false);
        toast.error('An error occured while creating ServiceNow Issue.');
      });
  };

  const redirectJiraTickets = (url) => {
    window.open(url, '_blank');
  };

  const disabled = actionButtonDisabled();

  return (
    <>
      <Stack
        divider={<Divider orientation="vertical" flexItem />}
        direction="column"
        justifyContent="center"
        spacing={isMobile ? 1 : 1.5}
      >
        <Stack
          divider={<Divider orientation="vertical" flexItem />}
          direction="row"
          justifyContent="center"
          spacing={isMobile ? 1 : 1.5}
        >
          {onSave && <Tooltip
            title={disabled ? 'You are not allowed to change details.' : ''}
            arrow
          >
            <span>
              <LoadingButton
                startIcon={<SaveIcon />}
                onClick={onSave}
                loading={loading.save}
                variant="outlined"
                color="primary"
                size="small"
                disabled={disabled}
              >
                Save
              </LoadingButton>
            </span>
          </Tooltip>}
          {certData.key && onChangeActive && <Tooltip
            title={disabled ? 'You are not allowed to change certficate status.' : ''}
            arrow
          >
            <span>
              <LoadingButton
                startIcon={certInactive && <ErrorOutlineIcon />}
                onClick={confirmStatusChange}
                loading={loading.active}
                variant="outlined"
                color={certInactive ? 'warning' : 'primary'}
                size="small"
                disabled={disabled}
              >
                {certInactive ? 'Mark active' : 'Mark Inactive'}
              </LoadingButton>
            </span>
          </Tooltip>}
          {onDelete && <Tooltip
            title={disabled ? 'You are not allowed to delete certificate' : ''}
            arrow
          >
            <span>
              <LoadingButton
                startIcon={<DeleteIcon />}
                onClick={confirmDelete}
                loading={loading.delete}
                variant="outlined"
                color="error"
                size="small"
                disabled={disabled}
              >
                Delete
              </LoadingButton>
            </span>
          </Tooltip>}
          {onCancel && <LoadingButton
            startIcon={<ArrowBackIcon />}
            onClick={onCancel}
            variant="outlined"
            color="primary"
            size="small"
          >
            Back
          </LoadingButton>}
        </Stack>
        {certData.key && (
          <>
            <Stack
              divider={<Divider orientation="vertical" flexItem />}
              direction="row"
              justifyContent="center"
              spacing={isMobile ? 0 : 1.5}
            >
              <Typography variant="overline" gutterBottom color="primary">
                JIRA Issues
              </Typography>
              {certData?.jira_issues?.map((issue) => (
                <Tooltip
                  title="Click to open JIRA issue"
                  arrow
                >
                  <LoadingButton
                    // color={blue[500]}
                    sx={{ height: '25px', fontSize: '12px', fontWeight: '600' }}
                    variant="contained"
                    size="small"
                    onClick={() => redirectJiraTickets(issue.link)}
                  >
                    {issue.key}
                  </LoadingButton>
                </Tooltip>))}
              <LoadingButton
                sx={{ height: '25px', fontSize: '12px' }}
                size="small"
                variant="outlined"
                onClick={() => { setJiraModelOpen(true); }}
                disabled={disabled}
              >
                + Create New
              </LoadingButton>
            </Stack>
            <Stack
              divider={<Divider orientation="vertical" flexItem />}
              direction="row"
              justifyContent="center"
              spacing={isMobile ? 0 : 1.5}
            >
              <Typography variant="overline" gutterBottom color="primary">
                ServiceNow Tickets
              </Typography>
              {certData?.servicenow_tickets?.map((issue) => (
                <Tooltip
                  title="Click to open JIRA issue"
                  arrow
                >
                  <LoadingButton
                    // color={blue[500]}
                    sx={{ height: '25px', fontSize: '12px', fontWeight: '600' }}
                    variant="contained"
                    size="small"
                    onClick={() => redirectJiraTickets(issue.link)}
                  >
                    {issue.key}
                  </LoadingButton>
                </Tooltip>))}
              <LoadingButton
                sx={{ height: '25px', fontSize: '12px' }}
                size="small"
                variant="outlined"
                onClick={() => setServiceNowModelOpen(true)}
                disabled={disabled}
              >
                + Create New
              </LoadingButton>
            </Stack>
          </>
        )}
      </Stack>
      <DraggableDialog
        title={
          <Breadcrumbs aria-label="breadcrumb">
            <Button>
              JIRA
            </Button>
            <Button>
              Cloud Engineering (CE)
            </Button>
            <Typography color="text.primary">New Task</Typography>
          </Breadcrumbs>
        }
        open={jiraModelOpen}
        saveState={savingJiraTicket}
        handleSave={saveJiraTicket}
        setOpenState={() => { setJiraModelOpen(!jiraModelOpen); }}
      >
        <JiraTicketForm
          onChange={jiraTicketFormValueChange}
          summary={jiraTicketData.summary}
          description={jiraTicketData.description}
        />
      </DraggableDialog>

      <DraggableDialog
        title={
          <Breadcrumbs aria-label="breadcrumb">
            <Button>
              Service Now
            </Button>
            <Button>
              Cloud Engineering (CE)
            </Button>
            <Typography color="text.primary">New Incident</Typography>
          </Breadcrumbs>
        }
        open={serviceNowModelOpen}
        saveState={savingServiceNowTicket}
        handleSave={saveServiceNowTicket}
        setOpenState={() => { setServiceNowModelOpen(!serviceNowModelOpen); }}
      >
        <JiraTicketForm
          onChange={serviceNowTicketFormValueChange}
          summary={serviceNowTicketData.summary}
          description={serviceNowTicketData.description}
        />
      </DraggableDialog>
      <ToastContainer />
    </>
  );
}

CertficateActionButtons.propTypes = {
  source: PropTypes.string,
  certData: PropTypes.instanceOf(PropTypes.object),
  onSave: PropTypes.func,
  onChangeActive: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.instanceOf(PropTypes.object),
  certPortalRedirectURL: PropTypes.string,
  updateCertData: PropTypes.func,
};

CertficateActionButtons.defaultProps = {
  loading: false,
};

export default CertficateActionButtons;
